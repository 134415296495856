import React, {useState, useEffect } from "react";
import { BodyPage } from "../../components/BodyPage";
import { BodySearch } from "../../components/BodySearch";
import { LeftMenu } from "../../components/LeftMenu";
import { MainLogo } from "../../components/MainLogo";
import { RightMenu } from "../../components/RightMenu";
import "./style.css";
import { BODY_COUNT_PER_PAGE } from "../../config/constant";
import { Link, useNavigate } from "react-router-dom";
import CodeApi from "../../api/codes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from '@fortawesome/free-solid-svg-icons';

import { risks, riskOptions } from "../../config/codes";

export const MalsellerList = ({ dataParam, nationParam, platformParam, riskParam, searchTypeParam, searchTextParam, pageParam, onPageSubmit, onNationSubmit, onPlatformSubmit, onRiskSubmit, onSearchTextSubmit }) => {
  const datas = dataParam ? dataParam.results : null;
  const pageInfo = dataParam ? {"totalCount": dataParam.count, "todayCount": dataParam.today, "accumCount": dataParam.accum, "page": pageParam, "prevPage": dataParam.previous, "nextPage": dataParam.next} : null;
  const navigate = useNavigate();

  // 검색 옵션
  const searchsDict = [
    { value: '', label: '전체' },
    { value: 'determin_name', label: '제목' },
    { value: 'seller', label: '판매상' },
  ];


  // 검색 타입
  const [searchType, setSearchType] = useState(searchTypeParam);
  const handleSearchType = (e) => {
    let value = e.target.value;
    setSearchType(value);
    onSearchTextSubmit(value, searchText);
  };

  // 검색어
  const [searchText, setSearchText] = useState(searchTextParam);
  const handleSearchTextChange = (e) => {
    let value = e.target.value;
    setSearchText(value);
  };
  const handleKeyDown = (e) => {
    if(e.key === "Enter") {
      onSearchTextSubmit(searchType, searchText);
    }
  } 

  // 공통코드 구하기기
  const PullComCode = (category='', key='', depth = '0') => {
    const formData = { category: category, key: key, depth: depth }
    CodeApi.comCode(formData).then(response => {
      if(response.status === 200) {
        if (response.data.category === "continent") {
          if (response.data.depth === '0') {
            // setContinent(response.data.list.length > 0 ? response.data.list[0].value : '');
            // setContinents(response.data.list);
            PullComCode('continent', '', 1);
          }
          else if (response.data.depth === '1') {
            setNation(nationParam);
            setNations(response.data.list);
            setNationsDict(response.data.dict);
            PullComCode('continent', nationParam, 2);
          }
          else if (response.data.depth === '2') {
            setPlatform(platformParam);
            setPlatforms(response.data.list);
            setPlatformsDict(response.data.dict);
          }
        }
        else if (response.data.category === "nation") {
          if (response.data.depth === '0') {
            setNations(response.data.list);
            setNationsDict(response.data.dict);
            PullComCode('platform', '', 0);
          }
          else if (response.data.depth === '1') {
            setPlatform(platformParam);
            setPlatforms(response.data.list);
            setPlatformsDict(response.data.dict);
          }
        }
        else if (response.data.category === "platform") {
          if (response.data.depth === '0') {
            setPlatforms(response.data.list);
            setPlatformsDict(response.data.dict);
          }
        }
      }
    }).catch(error => {
      console.log(error.message);
      alert(error.message)
    });    
    return [];
  }

  // 국가
  const [nation, setNation] = useState(nationParam);
  const [nations, setNations] = useState([])
  const [nationsDict, setNationsDict] = useState({})
  const handleNation = (e) => {
    let value = e.target.value;
    setNation(value);
    if (value === '') {
      PullComCode('continent', '', '1');
    } else {
      PullComCode('nation', value, 1);
    }
    onNationSubmit(value, '');
  };

  // 플랫폼
  const [platform, setPlatform] = useState(platformParam);
  const [platforms, setPlatforms] = useState([])
  const [platformsDict, setPlatformsDict] = useState({})
  const handlePlatform = (e) => {
    let value = e.target.value;
    setPlatform(value);
    onPlatformSubmit(value);
  };


  // 위험도
  const [risk, setRisk] = useState(riskParam);
  const handleRisk = (e) => {
    let value = e.target.value;
    setRisk(value);
    onRiskSubmit(value);
  };

  function SequenceNumber(currIndex) {
    return (pageParam-1) * BODY_COUNT_PER_PAGE + currIndex;
  }

  // 상세 페이지 이동
  const handleDetailSubmit = (seller) => {
    localStorage.setItem('nation', nation);
    localStorage.setItem('platform', platform);
    localStorage.setItem('risk', risk);
    localStorage.setItem('searchType', searchType);
    localStorage.setItem('searchText', searchText);
    localStorage.setItem('page', pageInfo.currPage);
    navigate(`/malsellerdetail/?seller=${seller}`);
  }

  // 스크롤 이벤트를 통해 FooterPanel 의 포지션을 동적으로 할당
  useEffect(() => {
    PullComCode('continent', '', '1');  // level(0: continent, 1: nation,2: platform)
  },[]);

  return (
    <div className="malseller-list">
      <div className="div-3">
        <div className="menu-section-2">
          <MainLogo className="design-component-instance-node" />
          <RightMenu className="right-menu-2" />
        </div>

        <div className="middle-section-2">
          <LeftMenu className="left-menu-2" />
          <div className="body-area-2">
            <div className="title-wrapper">
              <div className="title-2">총 판매상 : {pageInfo ? pageInfo.totalCount : null}</div>
            </div>

            <div className="body-head-2">
              <div className="dropdown-wrapper">
                {/* <div className="dropdown-5">
                  <div className="element-2">저작권</div>
                </div> */}
                <input className="dropdown-5" type="text" name="searchText" value={searchText} onChange={handleSearchTextChange} onKeyDown={(e) => handleKeyDown(e)} placeholder="판매상 검색">
                </input>

              </div>

              <div className="nation-2">
                <div className="dropdown-6">
                  <div className="text-wrapper-12">국가</div>
                </div>

                <div className="dropdown-5">
                  {/* <div className="text-wrapper-13">저작권</div> */}
                  <select className="text-wrapper-13" style={{width:"90px", height:"25px", border:"none"}} id="nation" name="nation" value={nation} onChange={(e) => handleNation(e)}>
                    { nations.map((option, index, total) => ( 
                      <option value={option.value}>{option.label}</option>
                    ))}
                  </select>                  


                </div>
              </div>

              <div className="platform-2">
                <div className="dropdown-7">
                  <div className="text-wrapper-12">플랫폼</div>
                </div>

                <div className="dropdown-5">
                  {/* <div className="text-wrapper-13">저작권</div> */}
                  <select className="text-wrapper-13" style={{width:"80px", height:"25px", border:"none"}} id="platform" name="platform" value={platform} onChange={(e) => handlePlatform(e)}>
                    { platforms.map((option, index, total) => ( 
                      <option value={option.value}>{option.label}</option>
                    ))}
                  </select>                  

                </div>
              </div>

              <div className="seller-risk-2">
                <div className="dropdown-8">
                  <div className="text-wrapper-12">판매상 위험도</div>
                </div>

                <div className="dropdown-5">
                  {/* <div className="text-wrapper-13">저작권</div> */}
                  <select className="text-wrapper-13" style={{width:"80px", height:"25px", border:"none"}} id="risk" name="risk" value={risk} onChange={(e) => handleRisk(e)}>
                    { riskOptions.map((option, index, total) => ( 
                      <option value={option.value}>{option.label}</option>
                    ))}
                  </select>                  

                </div>
              </div>
            </div>

            <div className="bulletin-body-table">
              <div className="form-table">

                <div className="head-frame">
                  <div className="body-row-cell">
                    <div className="text-wrapper-14">번호</div>
                  </div>

                  <div className="body-row-cell-2">
                    <div className="text-wrapper-15">판매상명</div>
                  </div>

                  <div className="body-row-cell-3">
                    <div className="text-wrapper-14">위험도</div>
                  </div>

                  <div className="body-row-cell-4">
                    <div className="text-wrapper-16">플랫폼</div>
                  </div>

                  <div className="body-row-cell-3">
                    <div className="text-wrapper-14">판매량(누적)</div>
                  </div>

                  <div className="body-row-cell-3">
                    <div className="text-wrapper-14">신고횟수</div>
                  </div>

                  <div className="body-row-cell-3">
                    <div className="text-wrapper-14">전체링크수</div>
                  </div>

                  <div className="body-row-cell-3">
                    <div className="text-wrapper-14">삭제된링크수</div>
                  </div>

                  <div className="body-row-cell-3">
                    <div className="text-wrapper-14">남은링크수</div>
                  </div>

                  <div className="body-row-cell-3">
                    <div className="text-wrapper-14">미통과수</div>
                  </div>

                  <div className="body-row-cell-3">
                    <div className="text-wrapper-14">최근신고일</div>
                  </div>
                </div>

                {datas ? datas
                .filter((dtm) => risk === null || risk.length === 0 || dtm.risk === risk)
                .map((dtm, index, total) => (
                <div className="record-frame" key={dtm.id} style={{cursor:"pointer"}} onClick={()=>{handleDetailSubmit(dtm.seller)}}>

                  <div className="body-row-data">
                    <div className="text-wrapper-17">{SequenceNumber(index+1)}</div>
                  </div>

                  <div className="body-row-data-2">
                    <div className="text-wrapper-18">{dtm.seller.substring(0,10)}</div>
                  </div>

                  <div className="body-row-data-3">
                    <div className="text-wrapper-19">{dtm.risk}</div>
                  </div>

                  <div className="body-row-data-4">
                    <div className="text-wrapper-17">{platformsDict[dtm.platform]}</div>
                  </div>

                  <div className="body-row-data-3">
                    <div className="text-wrapper-19">{dtm.sold_volume.toLocaleString('ko-KR')}</div>
                  </div>

                  <div className="body-row-data-3">
                    <div className="text-wrapper-19">{(dtm.apply).toLocaleString('ko-KR')}</div>
                  </div>

                  <div className="body-row-data-3">
                    <div className="text-wrapper-19">{dtm.count.toLocaleString('ko-KR')}</div>
                  </div>

                  <div className="body-row-data-3">
                    <div className="text-wrapper-19">{dtm.confirm.toLocaleString('ko-KR')}</div>
                  </div>

                  <div className="body-row-data-3">
                    <div className="text-wrapper-19">{dtm.ready.toLocaleString('ko-KR')}</div>
                  </div>

                  <div className="body-row-data-3">
                    <div className="text-wrapper-19">{dtm.passed.toLocaleString('ko-KR')}</div>
                  </div>

                  <div className="body-row-data-3">
                    <div className="text-wrapper-19">{dtm.reported_at && dtm.reported_at.substring(0,10)}</div>
                  </div>

                </div>
                )) : <></>}

              </div>
            </div>

            {/* <BodyPage className="body-page-instance" /> */}
            {pageInfo ?
              <BodyPage className="body-page-instance" pageInfo={pageInfo} onPageSubmit={onPageSubmit} />
            :<></>}

          </div>
        </div>
      </div>
    </div>
  );
};
