import React, { useState, useRef, useEffect } from "react";
import { MainLogo } from "../../components/MainLogo";
import { RightMenu } from "../../components/RightMenu";
import { LeftMenu } from "../../components/LeftMenu";
import { BACKEND_SERVER_BASEURL } from "../../config/constant";

import "./style.css";
import { Link, useNavigate } from 'react-router-dom';
import AuthApi from "../../api/auth";
import { useAuth } from "../../contexts/auth/auth.context";
import { Input } from "../../components/Input";

export const Login = () => {
  const { user, setUser, global, setGlobal } = useAuth();
  const [error, setError] = useState("");
  const [formData, setFormData] = useState({
    'email': '',
    'password': ''
  });

  const refLoginId = useRef();
  const refLoginPw = useRef();
  const navigate = useNavigate();

  // 비구조화 할당을 통해 값 추출
  const { email, password } = formData; 

  const onChange = e => {
    const { value, name } = e.target; // 우선 e.target 에서 name 과 value 를 추출
    setFormData({
      ...formData,  // 기존의 input 객체를 복사한 뒤
      [name]: value // name 키를 가진 값을 value 로 설정
    });
  };

  const onReset = () => {
    setFormData({
      email: '',
      password: ''
    });
    refLoginId.current.focus();
  };

  // validate Input Columns
  const validateInputs = () => {
    if (refLoginId.length <= 0) {
      const result = alert("이메일 아이디를 입력해 주세요!");
      refLoginId.current.focus();
      return false;
    }
    else if (refLoginPw.length <= 0) {
      const result = alert("비밀번호를 입력해 주세요!");
      refLoginPw.current.focus();
      return false;
    }
    return true;
  }

  const handleLoginSubmit = e => {
    e.preventDefault();
    if (validateInputs() === false) {
      return;
    } 
    AuthApi.Login(formData).then(response => {
      if(response.status == 200) {
        setProfile(response);
        navigate('/');
      } else {
        alert(response.data.msg)
      }
    }).catch(error => {
      console.log(error.message);
      alert('로그인 인증 오류(미승인)입니다.')
    })
  }
  const setProfile = (response) => {
    let user = { ...response.data.user };
    user = {
      ...user, 
      refresh: response.data.refresh,
      access: response.data.access,
    };
    setUser(user);

    // 인증에 성공한 email을 localStorage에 저장
    localStorage.setItem("user", JSON.stringify({ ...user, id: 0, access: '' }));
    localStorage.setItem('access_token', response.data.access);
    localStorage.setItem('refresh_token', response.data.refresh);    
  };

  useEffect(() => {
    // 사용자 정보 초기화
    setGlobal((global) => ({
      menuCategoryLevel: 0,
      menuCategoryCode: 'all',
      menuCategoryName: "전체",
      submenuCategoryCode: '',
      submenuCategoryName: ''      
    }));
  }, []);
  
  const NaverLogin = () => {
    window.location.href = NAVER_AUTH_URL;
  };
  
  return (
    <div className="login">
      <div className="div-2">
        <div className="middle-section">
          <LeftMenu className="left-menu-instance" />
          <div className="body-container">
            <div className="login-area">
              <div className="login-text">
                <div className="login-title">
                  <div className="text-wrapper-6">로그인</div>
                </div>

                <div className="login-sub-title">
                  <p className="p">
                    Trust Data 에 로그인 하시면 다양한 서비스를 편리하게 제공
                    받으실 수 있습니다.
                  </p>
                </div>
              </div>

              <div className="form-login">
                <div className="div-wrapper-2" style={{border:"none"}}>
                  {/* <div className="text-wrapper-7">이메일아이디</div> */}
                  <Input className="text-wrapper-7" inputType='text' name='email' value={email} onChange={onChange} ref={refLoginId}  text="이메일아이디"/>

                </div>

                <div className="div-wrapper-2" style={{border:"none"}}>
                  {/* <div className="text-wrapper-7">비밀번호</div> */}
                  <Input className="text-wrapper-7" inputType='password' name='password' value={password} onChange={onChange}  ref={refLoginPw} text="비밀번호"/>

                </div>

                <div className="login-btn-frame" style={{cursor:"pointer", backgroundColor:"#163E64" }} onClick={(e) => handleLoginSubmit(e)}>
                  <div className="login-btn">로 그 인</div>
                </div>

                <div className="auth-area">
                  <Link className="forget-auth" to={'/password'}>
                    <div className="text-wrapper-8">이메일/비밀번호 찾기</div>
                  </Link>

                  <Link className="registry-auth" to={'/signupagree'}>
                    <div className="text-wrapper-8">회원가입</div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="menu-section">
          <MainLogo className="main-logo-instance" />
          <RightMenu className="right-menu-instance" />
        </div>
      </div>
    </div>
  );
};
