import React, { useEffect } from "react";
import { Link, Navigate, useSearchParams } from "react-router-dom";
import { MalsellerDetailContainer } from "../../containers/MalsellerDetailContainer";
import { useAuth } from "../../contexts/auth/auth.context";

export const MalsellerDetailPage = () => {
  const { user, setUser, global, setGlobal } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const sellerParam = searchParams.get("seller")

  // 인수로 넘어온값이 없는 경우 Default 값 설정
  const seller = (sellerParam && sellerParam.length > 0) ? sellerParam : '';

  return (user && user.access && user.access.length > 200 ?
    <MalsellerDetailContainer sellerParam={seller} 
    /> : <Navigate to="/login" replace />
  );
}

