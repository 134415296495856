import React, { useEffect } from "react";
import { Link, Navigate, useSearchParams } from "react-router-dom";
import { InvestgateListContainer } from "../../containers/InvestgateListContainer";
import { useAuth } from "../../contexts/auth/auth.context";

export const InvestgateListPage = () => {
  const { user, setUser, global, setGlobal } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const platformParam = searchParams.get("platform")
  const progressParam = searchParams.get("progress")
  const toDateParam = searchParams.get("toDate")
  const searchTypeParam = searchParams.get("searchType")
  const searchParam = searchParams.get("search")
  const pageParam = searchParams.get("page")

  // 인수로 넘어온값이 없는 경우 Default 값 설정
  const platform = (platformParam && platformParam.length > 0) ? platformParam : '';
  const progress = (progressParam && progressParam.length > 0) ? progressParam : ''; 
  const toDate = (toDateParam && toDateParam.length > 0) ? toDateParam : '';
  const searchType = (searchTypeParam && searchTypeParam.length > 0) ? searchTypeParam : 'all';
  const search = (searchParam && searchParam.length > 0) ? searchParam : '';
  const page = (pageParam && pageParam.length > 0) ? pageParam : 1;

  return (user && user.access && user.access.length > 200 ?
    <InvestgateListContainer 
      platformParam={platform} 
      progressParam={progress} 
      toDateParam={toDate} 
      searchTypeParam={searchType} 
      searchParam={search} 
      pageParam={page}
    /> : <Navigate to="/login" replace />
  );
}

