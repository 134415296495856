import React, { useState, useRef, useContext, useEffect } from "react";
import { MainLogo } from "../MainLogo";
import { LeftMenu } from "../../components/LeftMenu";
import { RightMenu } from "../RightMenu";
import "./style.css";

import { useAuth } from "../../contexts/auth/auth.context";
import { BACKEND_SERVER_BASEURL } from "../../config/constant";
import { FaRegStar, FaHeart } from 'react-icons/fa6'
import * as productApi from "../../api/products";
import ConfirmContext from '../../contexts/confirm/ConfirmContext';
import { Link, useNavigate } from "react-router-dom";
import ForgeryApi from "../../api/forgerys";
import useForm from '../hooks/useForm';
import ReportApi from "../../api/reports";

import { infringeTypes, infringeTypeOptions } from "../../config/codes";
import { platforms, platformOptions } from "../../config/codes";
import { categories, categoryOptions } from "../../config/codes";
import { currencyUnits, currencyUnitOptions } from "../../config/codes";

export const ForgeryReport = ({prd}) => {
  const [ position, setPostion ] = useState({ header: false, mainer:false, footer:false,});
  const [ bodyBottom, setBodyBottom ] = useState(100000);
  
  const { user, setUser, global, setGlobal } = useAuth();
  const [ determinDesc, setDeterminDesc ] = useState('');
  const [ reviewed, setReviewed ] = useState(0);  
  const [ jump, setJump ] = useState('');
  const [ fixedTab, setFixedTab ] = useState(false);
  const { confirm: confirmComp } = useContext(ConfirmContext);  

  const [dtms, setDtms] = useState(prd ? JSON.parse(prd.determins) : []);

  const [ thumbClickedUrl, setThumbClickedUrl ] = useState((dtms && dtms[0].determin_image) ? BACKEND_SERVER_BASEURL + "/media/" +  dtms[0].determin_image : null  );
  const [ imageChecked, setImageChecked ] = useState(false);
  const [error, setError] = useState("");

  const [{infringe, content}, onChange, onReset] = useForm({
    'infringe': prd.infringe,
    'content': prd.content
  });

  const [infringeOption, setInfringeOption] = useState(prd.infringe);
  const handleInfringeChange = (event) => {
    let value = event.target.value;
    setInfringeOption(value);
  };

  // 출처
  const origins = {
    'product': '정상품',
    'official': '공홈',
    'determined': '기판별품',
  }  

  const refThumbMain = useRef();
  const refProductImage = useRef();
  const refProductFile = useRef();
  const refDeterminImage = useRef();
  const refDeterminFile = useRef();
  const refSrcName = useRef();
  const refSrcDesc = useRef();
  const refSimilarity = useRef();
  const navigate = useNavigate();

  function image_similarity_string(image_similar_list) {
    let data = '';
    image_similar_list.map((image_similar, index, total) => {
      data += image_similar.similarity.toFixed(2) + ', ';
    });
    if (data.substring(data.length-2) === ", ") {
      data = data.substring(0, data.length-2);
    } 
    return data;
  }

  const onDelete = (_id, e) => {
    e.preventDefault();

    // 위조품 데이터 재거 서버에 전송
    let formData = new FormData();
    formData.append("id", _id);
    ForgeryApi.DeleteForgery(_id).then(response => {
      if(response.status >= 200 || response.status <= 204) {
        alert(`위조품 데이터가 삭제되었습니다.`);
        navigate(-1);
      } else {
        alert(response.statusText)
      }
    }).catch(error => {
      console.log(error.message);
      alert(error.message)
    })
  }

  function isNumeric(data) {
    return !isNaN(data);
  }
    
  // validate Input Columns
  const validateInputs = () => {
    if (infringeOption.length <= 0) {
      const result = alert("침해 사유를 선택해 주세요!");
      return false;
    }
    else if (content.length <= 0) {
      const result = alert("침해 내용을 기술해 주세요!");
      return false;
    }
    return true;
  }

  function InsertDeterminedData(formData) {
    ReportApi.InsertReport(formData).then(response => {
      if(response.status == 200 || response.status == 201) {
        alert("정상적으로 저장되었습니다.")
      } else {
        alert(response.statusText)
      }
    }).catch(error => {
      console.log(error.message);
      alert(error.message)
    })
  }

  function postDeterminedByClicked() {
    let formData = new FormData();
    formData.append("platform", prd.mall);
    formData.append("category", prd.category);
    formData.append("brand", prd.brand);
    formData.append("model", prd.model);

    formData.append("determin_code", prd.determin_code);
    formData.append("determin_name", prd.determin_name);
    formData.append("product_name", prd.product_name);
    formData.append("product_id", prd.product);

    formData.append("currency", prd.currency);
    formData.append("product_price", prd.product_price);
    formData.append("price_floor", prd.price_floor);
    formData.append("determin_price", prd.determin_price);
    formData.append("url", prd.url);

    let determins = []
    dtms.map( (dtm, index, total) => {
      formData.append(`product_image${index+1}`, dtm.product_image);
      formData.append(`determin_image${index+1}`, dtm.determin_image);
      formData.append(`product_report${index+1}`, dtm.productReport);
      formData.append(`determin_report${index+1}`, dtm.determinReport);
      determins.push({"product_image": dtm.product_image, "product_report": dtm.productReport, "determin_image": dtm.determin_image, "determin_report": dtm.determinReport,})
    });
    formData.append("determins", JSON.stringify(determins));
    formData.append("similarity", prd.similarity);
    formData.append("infringe", infringeOption);
    formData.append("content", content);
    formData.append("seller", prd.seller);
    formData.append("sold_volume", prd.sold_volume);
    InsertDeterminedData(formData);
  }

  const onSubmit = e => {
    e.preventDefault();
    if (validateInputs() == true) {
      postDeterminedByClicked();
    }  
  }

  const onPrevious = (e) => {
    navigate(-2);
  }
  
  const onImageCheck = (prd_param, e) => {
    e.preventDefault();
    let desc = '';
    let date = new Date();
    let tm = date.getTime();
    prd.product_similar.map((sim, index, total) => {
      if (sim === prd_param) {
        sim.checked = (sim.checked===undefined) ? true : !sim.checked;
        if (sim.checked === true) {
          let ext1 = sim.product.substring(sim.product.lastIndexOf('.') + 1);
          sim.product_file = 'product_' + tm + '.' + ext1;
          let ext2 = sim.determin.substring(sim.determin.lastIndexOf('.') + 1);
          sim.determin_file = 'determin_' + tm + '.' + ext2;
        }
      }
      if (sim.checked) {
        let dsc = sim.src_name;
        dsc += (sim.src_desc&&sim.src_desc.length > 0) ? '/' + sim.src_desc : '/';
        dsc += '/' + sim.similarity.toFixed(2);
        desc += desc.length > 0 ? ', ' + dsc : dsc;
      }
    })
    prd.official_similar.map((sim, index, total) => {
      if (sim === prd_param) {
        sim.checked = (sim.checked===undefined) ? true : !sim.checked;
        if (sim.checked === true) {
          let ext1 = sim.product.substring(sim.product.lastIndexOf('.') + 1);
          sim.product_file = 'product_' + tm + '.' + ext1;
          let ext2 = sim.determin.substring(sim.determin.lastIndexOf('.') + 1);
          sim.determin_file = 'determin_' + tm + '.' + ext2;
        }
      }
      if (sim.checked) {
        let dsc = sim.src_name;
        dsc += (sim.src_desc&&sim.src_desc.length > 0) ? '/' + sim.src_desc : '/';
        dsc += '/' + sim.similarity.toFixed(2);
        desc += desc.length > 0 ? ', ' + dsc : dsc;
      }
    })
    prd.determined_similar.map((sim, index, total) => {
      if (sim === prd_param) {
        sim.checked = (sim.checked===undefined) ? true : !sim.checked;
        if (sim.checked === true) {
          let ext1 = sim.product.substring(sim.product.lastIndexOf('.') + 1);
          sim.product_file = 'product_' + tm + '.' + ext1;
          let ext2 = sim.determin.substring(sim.determin.lastIndexOf('.') + 1);
          sim.determin_file = 'determin_' + tm + '.' + ext2;
        }
      }
      if (sim.checked) {
        let dsc = sim.src_name;
        dsc += (sim.src_desc&&sim.src_desc.length > 0) ? '/' + sim.src_desc : '/';
        dsc += '/' + sim.similarity.toFixed(2);
        desc += desc.length > 0 ? ', ' + dsc : dsc;
      }
    })
    setImageChecked(!imageChecked);
    setDeterminDesc(desc);
  }

  const handleProductReportChange = (e, dtm_param) => {
    const { value, name } = e.target;
    const nextDtms = dtms.map(dtm => {
      if (dtm === dtm_param)
        dtm.productReport = value;
      return dtm;
    });
    setDtms(nextDtms);
  };
  const handleDeterminReportChange = (e, dtm_param) => {
    // e.preventDefault();
    const { value, name } = e.target;
    const nextDtms = dtms.map(dtm => {
      if (dtm === dtm_param)
        dtm.determinReport = value;
      return dtm;
    });
    setDtms(nextDtms);
  };

  const onThumbClick = (determin_image, e) => {
    e.preventDefault();
    setThumbClickedUrl(BACKEND_SERVER_BASEURL + "/media/" + determin_image)
  }

  const onBackward = () => {
    navigate(-1); // 이전 페이지로 이동
  }
  
  // 스크롤 이벤트를 통해 FooterPanel 의 포지션을 동적으로 할당
  useEffect(() => {
    window.addEventListener('scroll', getFooterPostion);
    return () =>   window.removeEventListener('scroll', getFooterPostion);
  },[position]);
  // Footer를 MainBody 하단에 동적으로 붙이기 위함
  const getFooterPostion = () => {
  	const main = document.getElementById('mainbody');
    if (main) {
      const bottom = Math.max(Math.floor(window.scrollY+main.getBoundingClientRect().top+main.getBoundingClientRect().height+100), 1300);
      setBodyBottom(bottom);
    }
  }

  return (
    <div className="forgery-report">
      <div className="div-6">
        <div className="overlap-2">
          <div className="main-frame-3">
            <div className="body-title-2">
              <div className="title-3">위조품 신고</div>
            </div>

            <div className="section-frame-10">
              <div className="frame-40">
                <div className="frame-41">
                  {/* <img className="div-image-2" alt="Div image" src="https://cdn.animaapp.com/projects/6614ef6f5f16da1152470b09/releases/665935372ad594dd39825363/img/div-image-2@2x.png" /> */}
                  <div className="div-image-2" style={{backgroundImage: `url(${thumbClickedUrl})`, backgroundSize:'cover' }} ref={refThumbMain}/>

                </div>

                <div className="frame-42">
                  {/* <div className="frame-43" />
                  <div className="frame-44" />
                  <div className="frame-45" />
                  <div className="frame-46" />
                  <div className="frame-47" /> */}
                { 
                  dtms.map((dtm, index, total) => ( dtm && dtm.determin_image.length > 0 ?
                    <div className={`frame-${index+43}`} style={{cursor:'pointer', backgroundImage: `url(${BACKEND_SERVER_BASEURL}/media/${dtm.determin_image})`, backgroundSize:'contain', borderColor:'#dddddd', borderWidth: 2,borderStyle: 'solid'}} onClick={(e)=>{onThumbClick(dtm.determin_image, e)}} key={index} />
                    : null
                  ))
                }

                </div>
              </div>

              <div className="frame-48">
                <div className="frame-49">
                  <div className="text-wrapper-22">플랫폼 : {platforms[prd.mall]}</div>
                </div>

                <div className="frame-49">
                  <div className="text-wrapper-23">카테고리 : {categories[prd.category]}</div>
                </div>

                <div className="frame-50">
                  <p className="text-wrapper-24">
                  {prd.determin_name ? prd.determin_name.substring(0,100) : null}
                  </p>
                </div>

                <div className="frame-49">
                  <div className="text-wrapper-23">정상품 판매가 : {prd.product_price}</div>
                </div>

                <div className="frame-49">
                  <div className="text-wrapper-23">정상품 하한가 : {prd.price_floor}</div>
                </div>

                <div className="frame-49">
                  <div className="text-wrapper-23">위조품 판매가 : {prd.determin_price}</div>
                </div>

                <div className="frame-49">
                  <div className="text-wrapper-23">침해유형 : {infringeTypes[prd.infringe]}</div>
                </div>

                <div className="frame-49">
                  <a className="text-wrapper-23" href={prd.seller_home} target="_blank" style={{"color": "blue"}}>판매자 : {prd.seller} 홈</a>
                </div>

                <div className="frame-49">
                  <a className="text-wrapper-23"href={prd.url} target="_blank" style={{"color": "blue"}}>위조품 상세보기</a>
                </div>

                <div className="frame-49">
                  <div className="text-wrapper-23">수집일시 : {prd.created_at.substring(0,19)}</div>
                </div>

                <div className="frame-49">
                  <div className="text-wrapper-23"></div>
                </div>
              </div>
            </div>

            <div className="section-frame-11">
              <div className="frame-51">
                <div className="text-wrapper-25">타이틀유사도</div>
              </div>

              <div className="frame-52">
                <div className="frame-53">
                  <div className="text-wrapper-26">정품 제목</div>
                </div>

                <div className="frame-54">
                  <div className="text-wrapper-26">{prd.product_name}</div>
                </div>

                <div className="frame-54">
                  <div className="text-wrapper-26">위조품 제목</div>
                </div>

                <div className="frame-54">
                  <div className="text-wrapper-26">{prd.determin_name.substring(0,40)}</div>
                </div>
              </div>

              <div className="frame-52">
                <div className="frame-55">
                  <div className="text-wrapper-26">브랜드/모델</div>
                </div>

                <div className="frame-56">
                  <div className="text-wrapper-26">{prd.brand} / {prd.model}</div>
                </div>

                <div className="frame-56">
                  <div className="text-wrapper-26">타이틀 유사도</div>
                </div>

                <div className="frame-56">
                  <div className="text-wrapper-26">{parseFloat(prd.similarity).toFixed(2)}</div>
                </div>
              </div>
            </div>

            <div className="section-frame-11">
              <div className="frame-57">
                <div className="text-wrapper-25">가격 유사도({prd.currency ? currencyUnits[prd.currency] : null})</div>
              </div>

              <div className="frame-52">
                <div className="frame-53">
                  <div className="text-wrapper-26">정품 기준가</div>
                </div>

                <div className="frame-54">
                  <div className="text-wrapper-26">{prd.product_price ? prd.product_price.toLocaleString('ko-KR') : null}</div>
                </div>

                <div className="frame-54">
                  <div className="text-wrapper-26">위조품 가격</div>
                </div>

                <div className="frame-54">
                  <div className="text-wrapper-26">{prd.determin_price ? prd.determin_price.toLocaleString('ko-KR') : null}</div>
                </div>
              </div>

              <div className="frame-52">
                <div className="frame-55">
                  <div className="text-wrapper-26">정품 하한가</div>
                </div>

                <div className="frame-56">
                  <div className="text-wrapper-26">{prd.price_floor ? prd.price_floor.toLocaleString('ko-KR') : null}</div>
                </div>

                <div className="frame-56">
                  <div className="text-wrapper-26">정품 하한가 기준</div>
                </div>

                <div className="frame-56">
                  <div className="text-wrapper-26">{(prd.determin_price - prd.price_floor).toLocaleString('ko-KR')}</div>
                </div>
              </div>
            </div>

            <div className="section-frame-12">
              <div className="frame-49">
                <div className="text-wrapper-25">이미지 유사도</div>
              </div>
            </div>

            <div className="section-frame-13">

            { dtms && dtms.map((dtm, index, total) => ( 
              <div className="rec-item-frame-3">
                <div className="div-7">
                  <div className="thumbnail-3">
                    <div className="overlap-group-3">
                      <div className="back-image-frame-2">
                        {/* <img className="back-image-3" alt="Back image" src="https://cdn.animaapp.com/projects/6614ef6f5f16da1152470b09/releases/665935372ad594dd39825363/img/div-img@2x.png"/> */}
                        <img className="back-image-3" style={{backgroundImage: `url(${BACKEND_SERVER_BASEURL}/media/${dtm.product_image})`, backgroundSize:'contain'}} />

                      </div>

                      {/* <button className="button-3">
                        <div className="button-box-3"></div>
                      </button> */}
                    </div>
                  </div>

                  <div className="report-frame-3">
                    {/* <div className="text-wrapper-27">11</div> */}
                    <textarea className="text-wrapper-27" name="productReport" value={dtm.productReport} style={{border:"none"}} onChange={(e) => handleProductReportChange(e, dtm)}></textarea>

                  </div>
                </div>

                <div className="div-7">
                  <div className="thumbnail-3">
                    <div className="overlap-group-3">
                      <div className="back-image-frame-2">
                        {/* <img className="back-image-3" alt="Back image" src="https://cdn.animaapp.com/projects/6614ef6f5f16da1152470b09/releases/665935372ad594dd39825363/img/div-img@2x.png" /> */}
                        <img className="back-image-3" style={{backgroundImage: `url(${BACKEND_SERVER_BASEURL}/media/${dtm.determin_image})`, backgroundSize:'contain'}}/>

                      </div>

                      {/* <button className="button-3">
                        <div className="button-box-3"></div>
                      </button> */}
                    </div>
                  </div>

                  <div className="report-frame-4">
                    {/* <div className="text-wrapper-27">11</div> */}
                    <textarea className="text-wrapper-27" name="determinReport" value={dtm.determinReport} style={{border:"none"}} onChange={(e) => handleDeterminReportChange(e, dtm)}></textarea>

                  </div>
                </div>
              </div>
            ))}

              
            </div>

            {/* <div className="section-frame-14"> */}
            <form className="section-frame-14" name="frm" encType="multipart/form-data">
              <div className="form-title-frame-2">
                <div className="form-title-3">판별하기</div>
              </div>

              <div className="form-caption-frame-3">
                <div className="frame-58">
                  <div className="form-caption-3">판별 내용</div>
                </div>

                <div className="frame-59">
                  <div className="text-wrapper-28">침해 사유</div>
                </div>

                <div className="frame-60">
                  {/* <div className="text-wrapper-28">판별 사유</div> */}
                  <select className="text-wrapper-28" id="infringe" name="infringe" value={infringeOption} onChange={handleInfringeChange} style={{border:"none"}}>
                    { infringeTypeOptions.map((option, index, total) => ( 
                      <option value={option.value}>{option.label}</option>
                    ))}
                  </select>

                </div>
              </div>

              <div className="form-text-frame-2">
                {/* <div className="form-text-3">내용을 입력해 주세요</div> */}
                <textarea className="form-text-3" name="content" value={content} style={{border:"none"}} onChange={onChange}></textarea>

              </div>

              <div className="form-submit-frame-3">
                <div className="form-submit-layout-4" style={{cursor:'pointer'}} onClick={(e)=>{onPrevious(e)}}>
                  <div className="form-submit-5">목&nbsp;&nbsp;록</div>
                </div>

                <div className="form-submit-layout-5" style={{cursor:'pointer'}} onClick={onSubmit}>
                  <div className="form-submit-6">신&nbsp;&nbsp;고</div>
                </div>
              </div>
            </form>

          </div>
        </div>

        <MainLogo className="main-logo-3" />
        <RightMenu className="right-menu-3" />
        <LeftMenu className="left-menu-3" />
      </div>
    </div>
  );
};
