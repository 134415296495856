import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ReportList } from "../components/ReportList";
import { getReportList } from "../modules/reports";
import { useAuth } from "../contexts/auth/auth.context";

export const ReportListContainer = ({brandParam, nationParam, platformParam, infringeParam, searchTypeParam, searchTextParam, pageParam}) => {
  const { user, setUser, global, setGlobal } = useAuth();
  const [ brand, setBrand ] = useState(brandParam);
  const [ nation, setNation ] = useState(nationParam);
  const [ platform, setPlatform ] = useState(platformParam);
  const [ infringe, setInfringe ] = useState(infringeParam);
  const [ searchType, setSearchType ] = useState(searchTypeParam);
  const [ searchText, setSearchText ] = useState(searchTextParam);
  const [ page, setPage ] = useState(pageParam);

  const { data, loading, error} = useSelector((state) => state.reportReducer.reportlist);
  const dispatch = useDispatch();

  // 브랜드
  const onBrandSubmit = (brandParam) => {
    setPage(1);
    setBrand(brandParam);
  }

  // 국가
  const onNationSubmit = (nationParam, platformParam) => {
    setPage(1);
    setNation(nationParam);
    setPlatform(platformParam);
  }

  // 플랫폼
  const onPlatformSubmit = (platformParam) => {
    setPage(1);
    setPlatform(platformParam);
  }

  // 침해유형
  const onInfringeSubmit = (infringeParam) => {
    setPage(1);
    setInfringe(infringeParam);
  }

  // 검색어
  const onSearchTextSubmit = (searchTypeParam, searchTextParam) => {
    setPage(1);
    setSearchType(searchTypeParam);
    setSearchText(searchTextParam);
  }

  // 페이지
  const onPageSubmit = (pageParam) => {
    setPage(pageParam);
  }

  // 컴포넌트 마운트 후 포스트 목록 요청
  useEffect(() => {
    dispatch(getReportList(brand, nation, platform, infringe, searchType, searchText, page, user.access));
  }, [dispatch, brand, nation, platform, infringe, searchType, searchText, page]);

  if ((loading && !data)) return <div>로딩중...</div>;
  if (error) return <div>에러 발생!</div>;

  return (
    <ReportList 
      dataParam={data}
      brandParam = {brand}
      nationParam = {nation}
      platformParam = {platform}
      infringeParam = {infringe}
      searchTypeParam = {searchType}
      searchTextParam = {searchText}
      pageParam =  {page >= 1 ? page : 1}
      onPageSubmit={onPageSubmit} 
      onBrandSubmit={onBrandSubmit} 
      onNationSubmit={onNationSubmit} 
      onPlatformSubmit={onPlatformSubmit} 
      onInfringeSubmit={onInfringeSubmit} 
      onSearchTextSubmit={onSearchTextSubmit}
    />
  );
}

