import { useParams } from "react-router";
import { Navigate } from "react-router-dom";
import { ProductDetailContainer } from "../../containers/ProductDetailContainer";
import { ProductListContainer } from "../../containers/ProductListContainer";
import { useAuth } from "../../contexts/auth/auth.context";

export const ProductDetailPage = () => {
  const { user, setUser, global, setGlobal } = useAuth();
  const { id } = useParams();  

  return (user && user.access && user.access.length > 200 ?
    <ProductDetailContainer id={parseInt(id)} 
    /> : <Navigate to="/login" replace />
  );
}
