import * as productApi from "../api/products";

import {
  reducerUtils,
  handleAsyncActionsList,
  handleAsyncActionsDetail,
  createPromiseSagaList,
  createPromiseSagaDetail,
} from "../lib/asyncUtils";
import { takeEvery, getContext, takeLatest } from "redux-saga/effects";

/* 액션 타입 */

// 상품목록 조회하기
const GET_PRODUCTLIST = "GET_PRODUCTLIST";
const GET_PRODUCTLIST_SUCCESS = "GET_PRODUCTLIST_SUCCESS";
const GET_PRODUCTLIST_ERROR = "GET_PRODUCTLIST_ERROR";

// 상품 항목 조회하기
const GET_PRODUCTDETAIL = "GET_PRODUCTDETAIL";
const GET_PRODUCTDETAIL_SUCCESS = "GET_PRODUCTDETAIL_SUCCESS";
const GET_PRODUCTDETAIL_ERROR = "GET_PRODUCTDETAIL_ERROR";

const CLEAR_PRODUCTTLIST = "CLEAR_PRODUCTTLIST";

export const getProductList = (brand, searchType, searchText, page, access) => ({
  type: GET_PRODUCTLIST,
  payload: { brand: brand, searchType: searchType, searchText: searchText, page: page, access: access },
});

export const getProductDetail = (access, id) => ({
  type: GET_PRODUCTDETAIL,
  payload: { access: access, id: id },
  meta: id,
});

export const clearProductList = () => ({ type: CLEAR_PRODUCTTLIST });

const getProductListSaga = createPromiseSagaList(
  GET_PRODUCTLIST,
  productApi.getProductList
);

const getProductDetailSaga = createPromiseSagaDetail(
  GET_PRODUCTDETAIL,
  productApi.getProductDetail
);

// 사가들을 합치기
export function* productSaga() {
  yield takeEvery(GET_PRODUCTLIST, getProductListSaga);
  yield takeEvery(GET_PRODUCTDETAIL, getProductDetailSaga);
}

// initialState 쪽도 반복되는 코드를 initial() 함수를 사용해서 리팩토링 했습니다.
const initialState = {
  productlist: reducerUtils.initial(),
  productdetail: reducerUtils.initial(),
};

export default function productReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PRODUCTLIST:
    case GET_PRODUCTLIST_SUCCESS:
    case GET_PRODUCTLIST_ERROR:
      const reducerList = handleAsyncActionsList(
        GET_PRODUCTLIST,
        "productlist",
        true
      );
      return reducerList(state, action);

    case GET_PRODUCTDETAIL:
    case GET_PRODUCTDETAIL_SUCCESS:
    case GET_PRODUCTDETAIL_ERROR:
      const reducerDetail = handleAsyncActionsDetail(
        GET_PRODUCTDETAIL,
        "productdetail",
        true
      );
      return reducerDetail(state, action);

    case CLEAR_PRODUCTTLIST:
      return {
        ...state,
        productlist: reducerUtils.initial(),
      };

    default:
      return state;
  }
}
