import React, { useEffect } from "react";
import { Link, Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { InquestListContainer } from "../../containers/InquestListContainer";
import { useAuth } from "../../contexts/auth/auth.context";

export const InquestListPage = () => {
  const { user, setUser, global, setGlobal } = useAuth();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = searchParams.get("page")
  const page = (pageParam && pageParam.length > 0) ? pageParam : 1;

  return (user && user.access && user.access.length > 200 ?
    <InquestListContainer 
      pageParam ={page} 
    /> : <Navigate to="/login" replace />  
  )
}

