import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ProductList } from "../components/ProductList";
import { getProductList, clearProductList } from "../modules/products";
import { useAuth } from "../contexts/auth/auth.context";

export const ProductListContainer = ({brandParam, searchTypeParam, searchTextParam, pageParam=1}) => {
  const { user, setUser, global, setGlobal } = useAuth();
  const [ brand, setBrand ] = useState(brandParam);
  const [ searchType, setSearchType ] = useState(searchTypeParam);
  const [ searchText, setSearchText ] = useState(searchTextParam);
  const [ page, setPage ] = useState(pageParam);

  const { data, loading, error} = useSelector((state) => state.productReducer.productlist);
  const dispatch = useDispatch();

  // 브랜드
  const onBrandSubmit = (brandParam) => {
    setPage(1);
    setBrand(brandParam);
  }

  // 검색어
  const onSearchTextSubmit = (searchTypeParam, searchTextParam) => {
    setPage(1);
    setSearchType(searchTypeParam);
    setSearchText(searchTextParam);
  }

  // 페이지
  const onPageSubmit = (pageParam) => {
    setPage(pageParam);
  }

  // 컴포넌트 마운트 후 목록 요청
  useEffect(() => {
    dispatch(getProductList(brand, searchType, searchText, page, user.access));
  }, [dispatch, brand, searchType, searchText, page]);

  if ((loading && !data)) return <div>로딩중...</div>;
  if (error) return <div>에러 발생!</div>;

  return (
    <ProductList 
      dataParam={data}
      brandParam = {brand}
      searchTypeParam = {searchType}
      searchTextParam = {searchText}
      pageParam = {page}
      onPageSubmit={onPageSubmit} 
      onBrandSubmit={onBrandSubmit} 
      onSearchTextSubmit={onSearchTextSubmit}
    />
  );
}

