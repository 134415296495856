import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { MalsellerList } from "../components/MalsellerList";
import { getMalsellerList } from "../modules/malsellers";
import { useAuth } from "../contexts/auth/auth.context";

export const MalsellerListContainer = ({nationParam, platformParam, riskParam, searchTypeParam, searchTextParam, pageParam}) => {
  const { user, setUser, global, setGlobal } = useAuth();

  const [ nation, setNation ] = useState(nationParam);
  const [ platform, setPlatform ] = useState(platformParam);
  const [ risk, setRisk ] = useState(riskParam);
  const [ searchType, setSearchType ] = useState(searchTypeParam);
  const [ searchText, setSearchText ] = useState(searchTextParam);
  const [ page, setPage ] = useState(pageParam);


  const { data, loading, error} = useSelector((state) => state.malsellerReducer.malsellerlist);
  const dispatch = useDispatch();

  // 국가
  const onNationSubmit = (nationParam, platformParam) => {
    setPage(1);
    setNation(nationParam);
    setPlatform(platformParam);
  }

  // 플랫폼
  const onPlatformSubmit = (platformParam) => {
    setPage(1);
    setPlatform(platformParam);
  }

  // 위험도
  const onRiskSubmit = (riskParam) => {
    setPage(1);
    setRisk(riskParam);
  }

  // 검색어
  const onSearchTextSubmit = (searchTypeParam, searchTextParam) => {
    setPage(1);
    setSearchType(searchTypeParam);
    setSearchText(searchTextParam);
  }

  // 페이지
  const onPageSubmit = (pageParam) => {
    setPage(pageParam);
  }


  // 컴포넌트 마운트 후 포스트 목록 요청
  useEffect(() => {
    dispatch(getMalsellerList(nation, platform, risk, searchType, searchText, page));
  }, [dispatch, nation, platform, risk, searchType, searchText, page]);

  if ((loading && !data)) return <div>로딩중...</div>;
  if (error) return <div>에러 발생!</div>;
  return (
    <MalsellerList 
      dataParam={data}
      nationParam = {nation}
      platformParam = {platform}
      riskParam = {risk}
      searchTypeParam = {searchType}
      searchTextParam = {searchText}
      pageParam =  {page >= 1 ? page : 1}
      onPageSubmit={onPageSubmit} 
      onNationSubmit={onNationSubmit} 
      onPlatformSubmit={onPlatformSubmit} 
      onRiskSubmit={onRiskSubmit} 
      onSearchTextSubmit={onSearchTextSubmit}
    />
  );
}

