import React, { useEffect } from "react";
import { Link, Navigate, useSearchParams, useNavigationType } from "react-router-dom";
import { MalsellerListContainer } from "../../containers/MalsellerListContainer";
import { useAuth } from "../../contexts/auth/auth.context";

export const MalsellerListPage = () => {
  const { user, setUser, global, setGlobal } = useAuth();
  const navType = useNavigationType();  

  // Filter Variables
  let nation = '';
  let platform = '';
  let risk = '';
  let searchType = '';
  let searchText = '';
  let page = '1';
  if (navType === 'PUSH') {
    localStorage.setItem('nation', nation);
    localStorage.setItem('platform', platform);
    localStorage.setItem('risk', risk);
    localStorage.setItem('searchType', searchType);
    localStorage.setItem('searchText', searchText);
    localStorage.setItem('page', page);
  } else {
    nation = localStorage.getItem('nation');
    platform = localStorage.getItem('platform');
    risk = localStorage.getItem('risk');
    searchType = localStorage.getItem('searchType');
    searchText = localStorage.getItem('searchText');
    page = localStorage.getItem('page');
  }

  return (user && user.access && user.access.length > 200 ?
    <MalsellerListContainer 
      nationParam={nation} 
      platformParam={platform} 
      riskParam={risk} 
      searchTypeParam={searchType} 
      searchTextParam={searchText} 
      pageParam={parseInt(page)} 
    /> : <Navigate to="/login" replace />
  );
}

