import { useParams } from "react-router";
import { Navigate } from "react-router-dom";
import { MerchantDetailContainer } from "../../containers/MerchantDetailContainer";
import { MerchantListContainer } from "../../containers/MerchantListContainer";
import { useAuth } from "../../contexts/auth/auth.context";

export const MerchantDetailPage = () => {
  const { user, setUser, global, setGlobal } = useAuth();
  const { _id } = useParams();  

  return (user && user.access && user.access.length > 200 ?
    <MerchantDetailContainer _id={_id} 
    /> : <Navigate to="/login" replace />
  )
}
