import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { AssessListPage } from "./screens/AssessListPage/AssessListPage";
import { AssessDetailPage } from "./screens/AssessDetailPage/AssessDetailPage";
import { CollectListPage } from "./screens/CollectListPage/CollectListPage";
import { CollectDetailPage } from "./screens/CollectDetailPage/CollectDetailPage";
import { DashboardListPage } from "./screens/DashboardListPage/DashboardListPage";
import { DeterminListPage } from "./screens/DeterminListPage/DeterminListPage";
import { DeterminDetailPage } from "./screens/DeterminDetailPage/DeterminDetailPage";
import { ForgeryListPage } from "./screens/ForgeryListPage/ForgeryListPage";
import { ForgeryDetailPage } from "./screens/ForgeryDetailPage/ForgeryDetailPage";
import { ForgeryReportPage } from "./screens/ForgeryReportPage/ForgeryReportPage";
import { InquestListPage } from "./screens/InquestListPage";
import { InvestgateListPage } from "./screens/InvestgateListPage/InvestgateListPage";
import { InvestgateDetailPage } from "./screens/InvestgateDetailPage/InvestgateDetailPage";
import { IprListPage } from "./screens/IprListPage/IprListPage";
import { IprDetailPage } from "./screens/IprDetailPage/IprDetailPage";
import { LawsuitListPage } from "./screens/LawsuitListPage/LawsuitListPage";
import { LawsuitDetailPage } from "./screens/LawsuitDetailPage/LawsuitDetailPage";
import { LoginPage } from "./screens/LoginPage";
import { PasswordPage } from "./screens/PasswordPage";
import { ChangePasswordPage } from "./screens/ChangePasswordPage";
import { MerchantListPage } from "./screens/MerchantListPage/MerchantListPage";
import { MerchantDetailPage } from "./screens/MerchantDetailPage/MerchantDetailPage";
import { MonitoringListPage } from "./screens/MonitoringListPage/MonitoringListPage";
import { MonitoringDetailPage } from "./screens/MonitoringDetailPage/MonitoringDetailPage";
import { ProductListPage } from "./screens/ProductListPage/ProductListPage";
import { ProductDetailPage } from "./screens/ProductDetailPage";
import { ReportListPage } from "./screens/ReportListPage/ReportListPage";
import { ReportDetailPage } from "./screens/ReportDetailPage/ReportDetailPage";
import { MalsellerListPage } from "./screens/MalsellerListPage/MalsellerListPage";
import { MalsellerDetailPage } from "./screens/MalsellerDetailPage/MalsellerDetailPage";
import { SellerListPage } from "./screens/SellerListPage/SellerListPage";
import { SignupAgreePage } from "./screens/SignupAgreePage/SignupAgreePage";
import { SignupRegistryPage } from "./screens/SignupRegistryPage";

const router = createBrowserRouter(
[
  {
    path: "/assesses",
    element: <AssessListPage />,
  },
  {
    path: "/assesslist",
    element: <AssessListPage />,
  },
  {
    path: "/assesses/:id",
    element: <AssessDetailPage />,
  },
  {
    path: "/investgates",
    element: <InvestgateListPage />,
  },
  {
    path: "/investgatelist",
    element: <InvestgateListPage />,
  },
  {
    path: "/investgates/:id",
    element: <InvestgateDetailPage />,
  },
  {
    path: "/dashboards",
    element: <DashboardListPage />,
  },
  {
    path: "/monitorings",
    element: <MonitoringListPage />,
  },
  {
    path: "/monitoringlist",
    element: <MonitoringListPage />,
  },
  {
    path: "/monitorings/:id",
    element: <MonitoringDetailPage />,
  },

  {
    path: "/*",
    element: <InquestListPage />,
  },
  {
    path: "/collects",
    element: <CollectListPage />,
  },
  {
    path: "/collectdetail/:_id",
    element: <CollectDetailPage />,
  },
  {
    path: "/determins",
    element: <DeterminListPage />,
  },
  {
    path: "/determinlist",
    element: <DeterminListPage />,
  },
  {
    path: "/determindetail/:_id",
    element: <DeterminDetailPage />,
  },
  {
    path: "/inquests",
    element: <InquestListPage />,
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/password",
    element: <PasswordPage />,
  },
  {
    path: "/signupagree",
    element: <SignupAgreePage />,
  },
  {
    path: "/signupregistry",
    element: <SignupRegistryPage />,
  },
  {
    path: "/changepwd",
    element: <ChangePasswordPage />,
  },
  {
    path: "/malsellers",
    element: <MalsellerListPage />,
  },
  {
    path: "/malsellerlist",
    element: <MalsellerListPage />,
  },
  {
    path: "/malsellerdetail",
    element: <MalsellerDetailPage />,
  },
  {
    path: "/sellers",
    element: <SellerListPage />,
  },
  {
    path: "/sellerlist",
    element: <SellerListPage />,
  },
  {
    path: "/merchants/:merchant",
    element: <MerchantListPage />,
  },
  {
    path: "/merchantlist",
    element: <MerchantListPage />,
  },
  {
    path: "/merchantdetail",
    element: <MerchantDetailPage />,
  },
  {
    path: "/reports",
    element: <ReportListPage />,
  },
  {
    path: "/reportlist",
    element: <ReportListPage />,
  },
  {
    path: "/reports/:id",
    element: <ReportDetailPage />,
  },
  {
    path: "/forgeryreports",
    element: <ReportListPage />,
  },
  // 브랜드 관리
  {
    // 지적재산권관리
    path: "/brands",
    element: <IprListPage />,
  },
  {
    // 지적재산권관리
    path: "/iprlist",
    element: <IprListPage />,
  },
  {
    // 지적재산권관리-상세정보
    path: "/iprdetail/:id",
    element: <IprDetailPage />,
  },
  {
    // 정품정보관리
    path: "/productlist",
    element: <ProductListPage />,
  },
  {
    // 정품정보관리-상세정보
    path: "/productdetail/:id",
    element: <ProductDetailPage />,
  },
  {
    // 가품정보관리
    path: "/forgeries",
    element: <ForgeryListPage />,
  },
  {
    // 가품정보관리
    path: "/forgerylist",
    element: <ForgeryListPage />,
  },
  {
    // 가품정보관리-상세정보
    path: "/forgerydetail/:id",
    element: <ForgeryDetailPage />,
  },
  {
    // 위조품 신고
    path: "/forgeryreport/:id",
    element: <ForgeryReportPage />,
  },
  {
    // 무료소송
    path: "/lawsuits",
    element: <LawsuitListPage />,
  },
  {
    // 무료소송
    path: "/lawsuitlist",
    element: <LawsuitListPage />,
  },
  {
    // 무료소송-상세정보
    path: "/lawsuitdetail/:id",
    element: <LawsuitDetailPage />,
  },
]);

export const App = () => {
  return <RouterProvider router={router} />;
};
