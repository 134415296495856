import React, { useEffect } from "react";
import { Link, useParams, Navigate, useNavigationType } from "react-router-dom";
import { MerchantListContainer } from "../../containers/MerchantListContainer";
import { useAuth } from "../../contexts/auth/auth.context";

export const MerchantListPage = () => {
  const { user, setUser, global, setGlobal } = useAuth();
  const navType = useNavigationType();  
  const { merchant } = useParams();  

  // Filter Variables
  let brand = '';
  let nation = '';
  let platform = '';
  let searchType = '';
  let searchText = '';
  let page = '0';
  if (navType === 'PUSH') {
    localStorage.setItem('brand', brand);
    localStorage.setItem('nation', nation);
    localStorage.setItem('platform', platform);
    localStorage.setItem('searchType', searchType);
    localStorage.setItem('searchText', searchText);
    localStorage.setItem('page', page);
  } else {
    brand = localStorage.getItem('brand');
    nation = localStorage.getItem('nation');
    platform = localStorage.getItem('platform');
    searchType = localStorage.getItem('searchType');
    searchText = localStorage.getItem('searchText');
    page = localStorage.getItem('page');
  }
    
  return (user && user.access && user.access.length > 200 ?
    <MerchantListContainer 
      brandParam={brand} 
      nationParam={nation} 
      platformParam={platform} 
      searchTypeParam={searchType} 
      searchTextParam={searchText}
      merchantParam={merchant} 
      pageParam={parseInt(page)} 
    /> : <Navigate to="/login" replace />
  );
}

