import React, {useState, useEffect } from "react";
import { LeftMenu } from "../../components/LeftMenu";
import { MainLogo } from "../../components/MainLogo";
import { RightMenu } from "../../components/RightMenu";
import "./style.css";
import { Link, useNavigate } from "react-router-dom";
import CodeApi from "../../api/codes";
import ProductApi from "../../api/products";
import DeterminApi from "../../api_elastic/determins";
import DeterminedApi from "../../api/determineds";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { BACKEND_SERVER_BASEURL } from "../../config/constant";
import moment from "moment"
import { FaRegStar, FaHeart } from 'react-icons/fa6'
import { useAuth } from "../../contexts/auth/auth.context";

import { infringeTypes, infringeTypeOptions } from "../../config/codes";

export const DeterminList = ({ dataParam, brandParam, nationParam, platformParam, searchTypeParam, searchTextParam, pageParam, onPageSubmit, onBrandSubmit, onNationSubmit, onPlatformSubmit, onSearchTextSubmit }) => {
  const { user, setUser, global, setGlobal } = useAuth();
  const datas = dataParam ? dataParam.hits : null;
  const pageInfo = dataParam ? {"totalCount": dataParam.total.value} : null;
  const BODY_COUNT_PER_PAGE = 24

  const navigate = useNavigate();
  const [todayCount, setTodayCount] = useState(0);
  const [accumCount, setAccumCount] = useState(0);
  const [ heartCheckeds, setHeartCheckeds ] = useState([]);

  // 검색 옵션
  const searchsDict = [
    { value: '', label: '제목+판매상' },
    { value: 'determin_name', label: '제목' },
    { value: 'seller', label: '판매상' },
  ];

  // 검색 타입
  const [searchType, setSearchType] = useState(searchTypeParam);
  const handleSearchType = (e) => {
    let value = e.target.value;
    setSearchType(value);
    onSearchTextSubmit(value, searchText);
  };

  // 검색어
  const [searchText, setSearchText] = useState(searchTextParam);
  const handleSearchTextChange = (e) => {
    let value = e.target.value;
    setSearchText(value);
  };
  const handleKeyDown = (e) => {
    if(e.key === "Enter") {
      onSearchTextSubmit(searchType, searchText);
    }
  } 

  // 브랜드 코드 구하기기
  const PullBrandCode = () => {
    let data = {}
    data['params'] = { include: '' }
    data['headers'] = {"Content-Type": "multipart/form-data"};
    ProductApi.getBrandCode(data).then(response => {
      if(response.status === 200) {
        // let value = response.data.list.length > 0 ? response.data.list[0].value : '';
        setBrand(brandParam);
        setBrands(response.data.list);
        // refBrand.current = value;
      }
    }).catch(error => {
      console.log(error.message);
      alert(error.message)
    });    
    return [];
  }

  // 브랜드
  const [brand, setBrand] = useState(brandParam);
  const [brands, setBrands] = useState([])
  const handleBrand = (e) => {
    let value = e.target.value;
    setBrand(value);
    onBrandSubmit(value);
    setPage(0);
  };

  // 공통코드 구하기기
  const PullComCode = (category='', key='', depth = '0') => {
    const formData = { category: category, key: key, depth: depth }
    CodeApi.comCode(formData).then(response => {
      if(response.status === 200) {
        if (response.data.category === "continent") {
          if (response.data.depth === '0') {
            // setContinent(continentParam);
            // setContinents(response.data.list);
            PullComCode('continent', '', 1);
          }
          else if (response.data.depth === '1') {
            setNation(nationParam);
            setNations(response.data.list);
            setNationsDict(response.data.dict);
            PullComCode('continent', nationParam, 2);
          }
          else if (response.data.depth === '2') {
            setPlatform(platformParam);
            setPlatforms(response.data.list);
            setPlatformsDict(response.data.dict);
          }
        }
        else if (response.data.category === "nation") {
          if (response.data.depth === '0') {
            setNations(response.data.list);
            setNationsDict(response.data.dict);
            PullComCode('platform', '', 0);
          }
          else if (response.data.depth === '1') {
            setPlatform(platformParam);
            setPlatforms(response.data.list);
            setPlatformsDict(response.data.dict);
          }
        }
        else if (response.data.category === "platform") {
          if (response.data.depth === '0') {
            setPlatforms(response.data.list);
            setPlatformsDict(response.data.dict);
          }
        }
      }
    }).catch(error => {
      console.log(error.message);
      alert(error.message)
    });    
    return [];
  }

  // 국가
  const [nation, setNation] = useState(nationParam);
  const [nations, setNations] = useState([])
  const [nationsDict, setNationsDict] = useState({})
  const handleNation = (e) => {
    let value = e.target.value;
    setNation(value);
    if (value === '') {
      PullComCode('continent', '', '1');
    } else {
      PullComCode('nation', value, 1);
    }
    onNationSubmit(value, '');
    setPage(0);
  };

  // 플랫폼
  const [platform, setPlatform] = useState(platformParam);
  const [platforms, setPlatforms] = useState([])
  const [platformsDict, setPlatformsDict] = useState({})
  const handlePlatform = (e) => {
    let value = e.target.value;
    setPlatform(value);
    onPlatformSubmit(value);
    setPage(0);
  };

  function disLR(lr) {
    let disabled = false;
    if (lr === "left") {
      if (page <= 0)
        disabled = true;
    } else {
      let totalPage = Math.ceil(pageInfo.totalCount / BODY_COUNT_PER_PAGE);  
      if (page >= totalPage-1)
        disabled = true;
    }
    // console.log(`${lr} => ${disabled}`);
    return disabled;
  }
  
  // 페이지 이동
  const [page, setPage] = useState(pageParam);
  const handlePage = (e, value) => {
    value = page + value;
    let totalPage = Math.ceil(pageInfo.totalCount / BODY_COUNT_PER_PAGE);    
    if (value <= 0) value = 0;
    else if (value > totalPage) value = totalPage-1;
    setPage(value);
    onPageSubmit(value);
  };

  function SequenceNumber(currIndex) {
    return (pageParam-1) * BODY_COUNT_PER_PAGE + currIndex;
  }

  const handleSellerHome = (e, url) => {
    e.preventDefault();
    window.open(url, '_blank');
  }

  // 오늘 수집 건수
  const GetTodayCollectCount = () => {
    const frDt = moment().format("YYYY-MM-DD") + "T00:00:00";       // 오늘 00시00분00초
    const frDate = moment(frDt).utc().format("YYYY-MM-DDTHH:mm:ss") // utc 시간 변환
    const toDt = moment().format("YYYY-MM-DDTHH:mm:ss");            // 오늘 현재 시간
    const toDate = moment(toDt).utc().format("YYYY-MM-DDTHH:mm:ss") // utc 시간 변환
    let params = { 
      query : { 
        bool: { 
          filter: 
          [ 
            { script: { script: "doc['determin_price'].value < doc['price_floor'].value"} }
          ]
        }
      }
    };
    if (brand.length > 0) {
      params.query.bool.filter.push({ match: { brand: `${brand}` }})
    }    
    if (nation.length > 0) {
      params.query.bool.filter.push({ match: { nation: `${nation}` }})
    }
    if (platform.length > 0) {
      params.query.bool.filter.push({ match: { mall: `${platform}` }})
    }
    if (searchType === "seller" && searchText.length > 0) {
        params.query.bool.filter.push({ match: { seller: `${searchText}` }})
    }
    else if (searchType === "determin_name" && searchText.length > 0) {
      params.query.bool.filter.push({ match: { determin_name: `${searchText}` }})
    }
    else if (searchText.length > 0) {
      params.query.bool.filter.push({ multi_match: { fields: ["seller", "determin_name"], query: `${searchText}` }})
    }
    params.query.bool.filter.push(   { "range": { "created_at": { "gte": `${frDate}`, "lt": `${toDate}` } } }    )
    DeterminApi.getCollectCount(params).then(response => {
      if(response.status === 200) {
        // console.log(JSON.stringify(params));
        setTodayCount(response.data.count);
      }
    }).catch(error => {
      console.log(error.message);
      alert(error.message)
    });    
  }

  // 누적 수집 건수
  const GetAccumCollectCount = () => {
    let params = { 
      query : { 
        bool: { 
          filter: 
          [ 
            { script: { script: "doc['determin_price'].value < doc['price_floor'].value"} }
          ]
        }
      }
    };
    if (brand.length > 0) {
      params.query.bool.filter.push({ match: { brand: `${brand}` }})
    }    
    if (nation.length > 0) {
      params.query.bool.filter.push({ match: { nation: `${nation}` }})
    }
    if (platform.length > 0) {
      params.query.bool.filter.push({ match: { mall: `${platform}` }})
    }
    if (searchType === "seller" && searchText.length > 0) {
        params.query.bool.filter.push({ match: { seller: `${searchText}` }})
    }
    else if (searchType === "determin_name" && searchText.length > 0) {
      params.query.bool.filter.push({ match: { determin_name: `${searchText}` }})
    }
    else if (searchText.length > 0) {
      params.query.bool.filter.push({ multi_match: { fields: ["seller", "determin_name"], query: `${searchText}` }})
    }
    DeterminApi.getCollectCount(params).then(response => {
      if(response.status === 200) {
        // console.log(JSON.stringify(params));
        setAccumCount(response.data.count);
      }
    }).catch(error => {
      console.log(error.message);
      alert(error.message)
    });    
  }

  // 이미지 하트 선택
  const onImageHeartCheck = (param_dtm, e) => {
    e.stopPropagation();    
    datas.map((dtm, index, total) => {
      if (dtm === param_dtm) {
        if (heartCheckeds.find((prm) => (prm._id === param_dtm._id))) {
          setHeartCheckeds(heartCheckeds.filter(prm => prm._id !== param_dtm._id));
        } else {
          setHeartCheckeds([...heartCheckeds, param_dtm]);
        }
      }
    })
  }

  // 선택 상품 삭제
  const handleRemoveSubmit = () => {
    let codes = [];
    let names = [];
    let malls = [];
    let elastic_ids = [];
    const params = { query: { terms: { _id: [] } } };
    heartCheckeds.map((prm, index, total) => {
      params.query.terms._id.push(prm._id);
      malls.push(prm._source.mall);
      codes.push(prm._source.determin_code);
      names.push(prm._source.determin_name);
      elastic_ids.push(prm._id);
    });

    // 엘라스틱에서 이 수집품 데이터 삭제
    DeterminApi.DeleteDetermin(params).then(response => {
      if(response.status == 200) {
        // 삭제 확인 코드 서버에 전송
        let formData = new FormData();
        formData.append("mall", JSON.stringify(malls));
        formData.append("determin_code", JSON.stringify(codes));
        formData.append("determin_name", JSON.stringify(names));
        formData.append("elastic_id", JSON.stringify(elastic_ids));
    
        // 이 수집품은 삭제되었음을 서버에 전송
        DeterminedApi.InsertConfirmed(formData).then(response => {
          if(response.status == 200 || response.status == 201) {
          } else {
            alert(response.statusText)
          }
        }).catch(error => {
          console.log(error.message);
          alert(error.message)
        })
        setHeartCheckeds([]);
        alert(`수집품 데이터가 삭제되었습니다.`);
        onPageSubmit(-1);
      } else {
        alert(response.data.msg)
      }
    }).catch(error => {
      console.log(error.message);
      alert(error.message)
    })
  }

  // 상세 페이지 이동
  const handleDetailSubmit = (_id) => {
    localStorage.setItem('brand', brand);
    localStorage.setItem('nation', nation);
    localStorage.setItem('platform', platform);
    localStorage.setItem('searchType', searchType);
    localStorage.setItem('searchText', searchText);
    localStorage.setItem('page', page);
    navigate(`/determindetail/${_id}`);
  }

  // 스크롤 이벤트를 통해 FooterPanel 의 포지션을 동적으로 할당
  useEffect(() => {
    // 브랜드
    PullBrandCode();

    // 국가/플랫폼 코드
    PullComCode('continent', '', '1');
  },[]);

  useEffect(() => {
    // 오늘 수집품 건수
    GetTodayCollectCount();
    // 누적 수집품 건수
    GetAccumCollectCount();
  },[brand, nation, platform, pageParam]);

  function displayImage(imgUrl) {
    if (imgUrl.startsWith('data/')) {
      imgUrl = `${BACKEND_SERVER_BASEURL}/media/${imgUrl}`;
    } else if (imgUrl.startsWith('/media/https%3A/')) {
      imgUrl = imgUrl.replace('/media/https%3A/', 'https://')
    } else if (imgUrl.startsWith('/media/')) {
      imgUrl = `${BACKEND_SERVER_BASEURL}${imgUrl}`;
    }
    // console.log('imgUrl : ' + imgUrl);
    return imgUrl;
  }

  function displayLocalDate(utc_dt) {
    let local_dt = moment(utc_dt, 'YYYY-MM-DDTHH:mm:ss');
    local_dt = local_dt.add(9, 'hours');
    return local_dt.format('YYYY-MM-DD');
  }


  return (
    <div className="determin-list">
      <div className="div-5">
        <div className="menu-section-2">
          <MainLogo className="design-component-instance-node" />
          <RightMenu className="right-menu-2" />
        </div>

        <div className="middle-section-2">
          <LeftMenu className="left-menu-2" />
          <div className="body-area-2">
            <div className="title-wrapper">
              <div className="title-2">
                오늘 : {todayCount.toLocaleString('ko-KR')} 건
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                누적 : {accumCount.toLocaleString('ko-KR')} 건
              </div>
            </div>

            <div className="body-head-2">
              <div className="search-2">
                <div className="dropdown-5">
                  {/* <div className="text-wrapper-14">판매상</div> */}
                  <select className="text-wrapper-14" style={{width:"80px", height:"25px", border:"none"}} id="searchType" name="searchType" value={searchType} onChange={(e) => handleSearchType(e)}>
                    { searchsDict.map((option, index, total) => ( 
                      <option value={option.value}>{option.label}</option>
                    ))}
                  </select>                  


                </div>

                <div className="dropdown-6">
                  {/* <div className="element-2">저작권</div> */}
                  <input className="element-2" type="text" name="searchText" value={searchText} onChange={handleSearchTextChange} onKeyDown={(e) => handleKeyDown(e)} placeholder="검색" style={{width:"80px", height:"25px", border:"none"}}/>


                </div>
              </div>

              <div className="div-6">
                <div className="dropdown-7">
                  <div className="text-wrapper-15">브랜드</div>
                </div>

                <div className="dropdown-6">
                  {/* <div className="text-wrapper-14">저작권</div> */}
                  <select className="text-wrapper-14" style={{width:"80px", height:"25px", border:"none"}} id="brand" name="brand" value={brand} onChange={(e) => handleBrand(e)}>
                    { brands.map((option, index, total) => ( 
                      <option value={option.value}>{option.label}</option>
                    ))}
                  </select>                  


                </div>
              </div>

              <div className="nation-2">
                <div className="dropdown-8">
                  <div className="text-wrapper-15">국가</div>
                </div>

                <div className="dropdown-6">
                  {/* <div className="text-wrapper-14">저작권</div> */}
                  <select className="text-wrapper-14" style={{width:"90px", height:"25px", border:"none"}} id="nation" name="nation" value={nation} onChange={(e) => handleNation(e)}>
                    { nations.map((option, index, total) => ( 
                      <option value={option.value}>{option.label}</option>
                    ))}
                  </select>                  


                </div>
              </div>

              <div className="div-6">
                <div className="dropdown-7">
                  <div className="text-wrapper-15">플랫폼</div>
                </div>

                <div className="dropdown-6">
                  {/* <div className="text-wrapper-14">저작권</div> */}
                  <select className="text-wrapper-14" style={{width:"80px", height:"25px", border:"none"}} id="platform" name="platform" value={platform} onChange={(e) => handlePlatform(e)}>
                    { platforms.map((option, index, total) => ( 
                      <option value={option.value}>{option.label}</option>
                    ))}
                  </select>                  


                </div>
              </div>

              {/* <div className="left-right-frame-2">
                <div className="div-wrapper-3">
                  <div className="text-wrapper-16"></div>
                </div>

                <div className="div-wrapper-3">
                  <div className="text-wrapper-16"></div>
                </div>

                <div className="div-wrapper-3">
                  <div className="text-wrapper-17">재거</div>
                </div>
              </div> */}

              <div className="left-right-frame-2">
                <button className="div-wrapper-3" disabled={pageInfo && disLR('left') ? true : false} style={{cursor: (pageInfo && disLR('left')) ? null : 'pointer'}} onClick={(e) => handlePage(e, -1)}>
                  <div className="text-wrapper-16"><FontAwesomeIcon icon={faChevronLeft} /></div>
                </button>

                <button className="div-wrapper-3" disabled={pageInfo && disLR('right') ? true : false} style={{cursor: (pageInfo && disLR('right')) ? null : 'pointer'}} onClick={(e) => handlePage(e, 1)}>
                  <div className="text-wrapper-16"><FontAwesomeIcon icon={faChevronRight} /></div>
                </button>

                <div className="div-wrapper-3" onClick={(e)=>{handleRemoveSubmit()}} style={{cursor:'pointer'}}>
                  <div className="text-wrapper-17">재거</div>
                </div>

              </div>

            </div>

            <div className="best-body-2">

              {datas && datas.map((dtm, index, total) => (
              <div className="best-item-2" onClick={()=>{handleDetailSubmit(dtm._id)}}  key={dtm._id}>

                <div className="overlap-group-wrapper">
                  <div className="button-wrapper" style={{cursor:'pointer', backgroundImage: `url(${dtm._source.thumb1})`, backgroundSize:'corner'}}>
                    <button className="button-2" style={{cursor:'pointer'}} onClick={(e)=>{onImageHeartCheck(dtm, e)}}>
                      <div className="button-box-2"><FaHeart size="24px" fill={(heartCheckeds.find((prm) => (prm._id ===dtm._id))) ? "tomato" : "gray"}/></div>
                    </button>
                  </div>
                </div>

                <div className="item-info-2">
                  <div className="item-title-text-wrapper">
                    <p className="p">
                    {dtm._source.determin_name && dtm._source.determin_name.substring(0,26)}
                    </p>
                  </div>

                  <div className="div-7">
                    <div className="text-wrapper-18">
                      {dtm._source.currency.toUpperCase()}
                      &nbsp;
                      {dtm._source.determin_price.toLocaleString('ko-KR')}
                      &nbsp;/&nbsp;
                      {dtm._source.price_floor.toLocaleString('ko-KR')}


                    </div>

                    <div className="text-wrapper-19">

                    </div>

                    <div className="text-wrapper-20">

                    </div>
                  </div>

                  <div className="div-7">
                    <div className="product-price-2">
                    {dtm._source.mall}

                    </div>

                    <div className="price-floor-2">

                    </div>

                    <div className="determin-price-2">

                    </div>
                  </div>

                  <div className="div-7" >
                    <div className="text-wrapper-18" onClick={(e) => handleSellerHome(e, dtm._source.seller_home)}>
                    {dtm._source.seller && dtm._source.seller.substring(0,10)}

                    </div>

                    <div className="text-wrapper-19">

                    </div>

                    <div className="text-wrapper-20">
                    {/* {dtm._source.created_at.substring(0,10)} */}
                    {displayLocalDate(dtm._source.created_at)}

                    </div>
                  </div>
                </div>
              </div>

              ))}
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
