import React, {useState, useEffect } from "react";
import { BodyPage } from "../../components/BodyPage";
import { BodySearch } from "../../components/BodySearch";
import { LeftMenu } from "../../components/LeftMenu";
import { MainLogo } from "../../components/MainLogo";
import { RightMenu } from "../../components/RightMenu";
import "./style.css";
import { BODY_COUNT_PER_PAGE } from "../../config/constant";
import { Link, useNavigate } from "react-router-dom";

export const IprList = ({ dataParam, currPage, onPageSubmit, onSearchSubmit }) => {
  const datas = dataParam ? dataParam.results : null;
  const summary = dataParam ? dataParam.summary : null;
  const pageInfo = dataParam ? {"totalCount": dataParam.count, "currPage": currPage, "prevPage": dataParam.previous, "nextPage": dataParam.next} : null;
  const [ position, setPostion ] = useState({ header: false, mainer:false, footer:false,});
  const [ bodyBottom, setBodyBottom ] = useState(100000);

  // 지재권유형
  const iprTypes = {brand: '상표', copyright: '저작권', design: '디자인', patent: '특허'};

  // 국가  
  const nationOptions = { 
    korea : "대한민국",	
    china : "중국",
    japan : "일본",
    taiwan : "대만",
    vietnam : "배트남",
    thailand : "태국",
    indonesia : "인도네시아",
  }

  // 세부검색
  const searchOptions = [
    { value: 'all', label: '전체' },
    { value: 'brand', label: '상표' },
    { value: 'copyright', label: '저작권' },
    { value: 'design', label: '디자인' },
    { value: 'patent', label: '특허' },
  ];

  function SequenceNumber(currIndex) {
    return (currPage-1) * BODY_COUNT_PER_PAGE + currIndex;
  }

  // 스크롤 이벤트를 통해 FooterPanel 의 포지션을 동적으로 할당
  useEffect(() => {
    window.addEventListener('scroll', getFooterPostion);
    return () =>   window.removeEventListener('scroll', getFooterPostion);
  },[position]);
  // Footer를 MainBody 하단에 동적으로 붙이기 위함
  const getFooterPostion = () => {
    const main = document.getElementById('mainbody');
    if (main) {
      const bottom = Math.max(Math.floor(window.scrollY+main.getBoundingClientRect().top+main.getBoundingClientRect().height+100), 1300);
      setBodyBottom(bottom);
    }
  }

  return (
    <div className="ipr-list">
      <div className="div-2">
        <div className="bulletin-body">
          <div className="body">
            <div className="bulletin-body-title">
              <div className="title">등록 지재권</div>
            </div>

            <div className="head">
              <div className="summary">
                <div className="summary-2">
                  <div className="summary-2">
                    <div className="text-wrapper-13">BEBEDEPINO</div>
                  </div>
                </div>

                <div className="summary-2">
                  <div className="summary-3">
                    <div className="text-wrapper-14">상 표</div>

                    <div className="text-wrapper-14">{summary && summary.length >= 1 ? summary[0].total : null}</div>
                  </div>

                  <div className="summary-4">
                    <div className="text-wrapper-14">저작권</div>

                    <div className="text-wrapper-14">{summary && summary.length >= 2 ? summary[1].total : null}</div>
                  </div>
                </div>

                <div className="summary-5">
                  <div className="summary-6">
                    <div className="text-wrapper-14">디자인</div>

                    <div className="text-wrapper-14">{summary && summary.length >= 3 ? summary[2].total : null}</div>
                  </div>

                  <div className="summary-7">
                    <div className="text-wrapper-14">특 허</div>

                    <div className="text-wrapper-14">{summary && summary.length >= 4 ? summary[3].total : null}</div>
                  </div>
                </div>
              </div>

              {/* <BodySearch className="design-component-instance-node" /> */}
              {pageInfo ?
              <BodySearch className="design-component-instance-node" searchOptions={searchOptions} onSearchSubmit={onSearchSubmit}/>
              :<></>}            

            </div>

            <div className="bulletin-body-table">
              <div className="form-table">

                <div className="head-frame">
                  <div className="body-row-cell">
                    <div className="text-wrapper-15">번호</div>
                  </div>

                  <div className="body-row-cell-2">
                    <div className="text-wrapper-16">브랜드</div>
                  </div>

                  <div className="body-row-cell-3">
                    <div className="text-wrapper-15">이미지</div>
                  </div>

                  <div className="body-row-cell-4">
                    <div className="text-wrapper-15">지재권 명칭</div>
                  </div>

                  <div className="body-row-cell-3">
                    <div className="text-wrapper-15">출원번호</div>
                  </div>

                  <div className="body-row-cell-3">
                    <div className="text-wrapper-15">등록번호</div>
                  </div>

                  <div className="body-row-cell-3">
                    <div className="text-wrapper-15">유형</div>
                  </div>

                  <div className="body-row-cell-3">
                    <div className="text-wrapper-15">국가</div>
                  </div>

                  <div className="body-row-cell-2">
                    <div className="text-wrapper-15">권리인</div>
                  </div>

                  <div className="body-row-cell-2">
                    <div className="text-wrapper-15">출원일</div>
                  </div>

                  <div className="body-row-cell-2">
                    <div className="text-wrapper-15">유효일</div>
                  </div>
                </div>

                {datas ? datas.map((prd, index, total) => (
                <Link className="record-frame" to={`/iprdetail/${prd.id}`} key={prd.id} style={{cursor:"pointer"}}>

                  <div className="body-row-data">
                    <div className="text-wrapper-17">{SequenceNumber(index+1)}</div>
                  </div>

                  <div className="body-row-data-2">
                    <div className="text-wrapper-17">{prd.brand}</div>
                  </div>

                  <div className="body-row-data-3">
                    <div className="text-wrapper-17"></div>
                  </div>

                  <div className="body-row-data-4">
                    <div className="text-wrapper-17">{prd.ipr_name}</div>
                  </div>

                  <div className="body-row-data-3">
                    <div className="text-wrapper-18">{prd.apply_no}</div>
                  </div>

                  <div className="body-row-data-3">
                    <div className="text-wrapper-17">{prd.license_no}</div>
                  </div>

                  <div className="body-row-data-3">
                    <div className="text-wrapper-17">{iprTypes[prd.ipr_type]}</div>
                  </div>

                  <div className="body-row-data-3">
                    <div className="text-wrapper-17">{nationOptions[prd.nation]}</div>
                  </div>

                  <div className="body-row-data-5">
                    <div className="text-wrapper-17">{prd.right_holder}</div>
                  </div>

                  <div className="body-row-data-5">
                    <div className="text-wrapper-17">{prd.applied_at ? prd.applied_at.substr(0,10) : null}</div>
                  </div>

                  <div className="body-row-data-5">
                    <div className="text-wrapper-17">{prd.expired_at ? prd.expired_at.substr(0,10) : null}</div>
                  </div>

                </Link>
                )) : <></>}


              </div>
            </div>

            {/* <BodyPage className="design-component-instance-node" /> */}
            {pageInfo ?
              <BodyPage className="design-component-instance-node" pageInfo={pageInfo} onPageSubmit={onPageSubmit} />
            :<></>}


          </div>
        </div>

        {/* <FooterPanel className="footer-panel-instance" /> */}
        <LeftMenu className="left-menu-instance" />
        <MainLogo className="main-logo-instance" />
        <RightMenu className="right-menu-instance" />
      </div>
    </div>
  );
};
