import axios from "./index";

// 상품 목록
export const getProductList = async (params) => {
  // params에 넘어온 인수중 access token이 들어 있는 경우 떼어서 header에 넣기
  let data = {}
  data['params'] = params;
  // if (params.access && params.access.length > 0) {
  //   data['headers'] = {Authorization: `Bearer ${params.access}`};
  // }
  // delete data.params.access;

  const response = await axios.get("/products/", data);
  return response.data;
};

// 상품 상세 정보
export const getProductDetail = async (params) => {
  // params에 넘어온 인수중 access token이 들어 있는 경우 떼어서 header에 넣기
  let data = {}
  // data['headers'] = {Authorization: `Bearer ${params.access}`};

  const response = await axios.get(`/products/${params.id}/`, data) 
  return response.data;
};

class ProductApi {
  // 브랜드명
  static getBrandCode = (data) => {
    const response = axios.get(`/brands/`, data);
    return response; 
  };

  // 상품 데이터 수정
  static UpdateProduct = (id, formData) => {
    let data = `/products/${id}/`
    return axios.post(data, formData, { 
      headers: {
        "Content-Type": "multipart/form-data"
      },
    });
  };

  
}
export default ProductApi;
