// 플랫폼
export const platformOptions =
[
    {
      "value": "",
      "label": "전체"
    },
    {
      "value": "1688",
      "label": "일육팔팔"
    },
    {
      "value": "alibaba",
      "label": "알리바바"
    },
    {
      "value": "amazon_us",
      "label": "아마존_미국"
    },
    {
      "value": "coupang",
      "label": "쿠팡"
    },
    {
      "value": "lazada",
      "label": "라자다"
    },
    {
      "value": "lazada_id",
      "label": "라자다_인도네시아"
    },
    {
      "value": "lazada_my",
      "label": "라자다_말레이시아"
    },
    {
      "value": "lazada_ph",
      "label": "라자다_필리핀"
    },
    {
      "value": "lazada_sg",
      "label": "라자다_싱가포르"
    },
    {
      "value": "lazada_th",
      "label": "라자다_태국"
    },
    {
      "value": "lazada_vn",
      "label": "라자다_베트남"
    },
    {
      "value": "naver",
      "label": "네이버스토어"
    },
    {
      "value": "shopee.co.id",
      "label": "쇼피_인도네시아"
    },
    {
      "value": "shopee.com.my",
      "label": "쇼피_말레이시아"
    },
    {
      "value": "shopee.ph",
      "label": "쇼피_필리핀"
    },
    {
      "value": "shopee.sg",
      "label": "쇼피_싱가포르"
    },
    {
      "value": "shopee.co.th",
      "label": "쇼피_태국"
    },
    {
      "value": "shopee.vn",
      "label": "쇼피_베트남"
    },
    {
      "value": "taobao",
      "label": "타오바오"
    },
    {
      "value": "temu",
      "label": "테무"
    }
];
export const platforms =  
{
    "": "전체",
    "1688": "일육팔팔",
    "alibaba": "알리바바",
    "amazon_us": "아마존_미국",
    "coupang": "쿠팡",
    "lazada": "라자다",
    "lazada_id": "라자다_인도네시아",
    "lazada_my": "라자다_말레이시아",
    "lazada_ph": "라자다_필리핀",
    "lazada_sg": "라자다_싱가포르",
    "lazada_th": "라자다_태국",
    "lazada_vn": "라자다_베트남",
    "naver": "네이버스토어",
    "shopee.co.id": "쇼피_인도네시아",
    "shopee.com.my": "쇼피_말레이시아",
    "shopee.ph": "쇼피_필리핀",
    "shopee.sg": "쇼피_싱가포르",
    "shopee.co.th": "쇼피_태국",
    "shopee.vn": "쇼피_베트남",
    "taobao": "타오바오",
    "temu": "테무"
}

//카테고리
export const categoryOptions =
[
    {
      "value": "",
      "label": "전체"
    },
    {
    "value": "appliance",
    "label": "가전"
    },
    {
    "value": "beauty",
    "label": "뷰티"
    },
    {
    "value": "clothes",
    "label": "의류"
    },
    {
    "value": "contents",
    "label": "컨텐츠"
    },
    {
    "value": "food",
    "label": "음식료"
    },
    {
    "value": "furniture",
    "label": "가구"
    },
    {
    "value": "infant",
    "label": "아동/유아"
    },
    {
    "value": "leasure",
    "label": "레저"
    },
    {
    "value": "oddsandends",
    "label": "잡동사니"
    }
]
export const categories = 
{
    "appliance": "가전",
    "beauty": "뷰티",
    "clothes": "의류",
    "contents": "컨텐츠",
    "food": "음식료",
    "furniture": "가구",
    "infant": "아동/유아",
    "leasure": "레저",
    "oddsandends": "잡동사니"
}

// 침해유형
export const infringeTypeOptions =
[
  {
    "value": "",
    "label": "전체"
  },
  {
    "value": "image_copy_head",
    "label": "이미지도용(한국공홈)"
  },
  {
    "value": "image_copy_tmall",
    "label": "이미지도용(티몰공홈)"
  },
  {
    "value": "detail_compare",
    "label": "정가품비교"
  },
  {
    "value": "keyword_theft",
    "label": "키워드도용"
  },
  {
    "value": "brand_damage",
    "label": "상표훼손"
  },
  {
    "value": "copyright_infringe",
    "label": "저작권침해"
  },
  {
    "value": "price_infringe",
    "label": "정품과동일"
  },
  {
    "value": "unproduced",
    "label": "미생산제품"
  }
]


export const infringeTypes =
{
  "image_copy_head": "이미지도용(한국공홈)",
  "image_copy_tmall": "이미지도용(티몰공홈)",
  "detail_compare": "정가품비교",
  "keyword_theft": "키워드도용",
  "brand_damage": "상표훼손",
  "copyright_infringe": "저작권침해",
  "price_infringe": "정품과동일",
  "unproduced": "미생산제품"
}

// 화폐
export const currencyUnitOptions =
[
    {
      "value": "",
      "label": "전체"
    },
    {
    "value": "cad",
    "label": "캐나다(달러)"
    },
    {
    "value": "cny",
    "label": "위안"
    },
    {
    "value": "eur",
    "label": "유로"
    },
    {
    "value": "gbp",
    "label": "파운드"
    },
    {
    "value": "idr",
    "label": "인도네시아(루피아)"
    },
    {
    "value": "jpy",
    "label": "엔"
    },
    {
    "value": "krw",
    "label": "원화"
    },
    {
    "value": "myr",
    "label": "말레이시아(링겟)"
    },
    {
    "value": "php",
    "label": "필리핀(페소)"
    },
    {
    "value": "sgd",
    "label": "싱가폴(달러)"
    },
    {
    "value": "thb",
    "label": "태국(바트)"
    },
    {
    "value": "twd",
    "label": "대만(달러)"
    },
    {
    "value": "usd",
    "label": "USD"
    },
    {
    "value": "vnd",
    "label": "베트남(동)"
    }
]
export const currencyUnits =
{
    "cad": "캐나다(달러)",
    "cny": "위안",
    "eur": "유로",
    "gbp": "파운드",
    "idr": "인도네시아(루피아)",
    "jpy": "엔",
    "krw": "원화",
    "myr": "말레이시아(링겟)",
    "php": "필리핀(페소)",
    "sgd": "싱가폴(달러)",
    "thb": "태국(바트)",
    "twd": "대만(달러)",
    "usd": "USD",
    "vnd": "베트남(동)"
}

// 신고방법
export const reportMethodOptions =
[
  {
    "value": "email",
    "label": "이메일"
  },
  {
    "value": "phone",
    "label": "전화"
  },
  {
    "value": "website",
    "label": "웹사이트"
  }
]
export const reportMethods =
{
  "email": "이메일",
  "phone": "전화",
  "website": "웹사이트"
}

// 신고결과
export const reportStatusOptions = 
[
  {
    "value": "ready",
    "label": "신고준비"
  },
  {
    "value": "apply",
    "label": "신고신청"
  },
  {
    "value": "confirm",
    "label": "신고확정"
  },
  {
    "value": "passed",
    "label": "미통과"
  },
  {
      "value": "revive",
      "label": "되살아남"
  }
]
export const reportStatuses =
{
  "ready": "신고준비",
  "apply": "신고신청",
  "confirm": "신고확정",
  "passed": "미통과",
  "revive": "되살아남"
}

// 국가
export const nationOptions = 
[
  {
    "value": "",
    "label": "전체"
  },
  {
    "value": "china",
    "label": "중국"
  },
  {
    "value": "indonesia",
    "label": "인도네시아"
  },
  {
    "value": "japan",
    "label": "일본"
  },
  {
    "value": "korea",
    "label": "대한민국"
  },
  {
    "value": "taiwan",
    "label": "대만"
  },
  {
    "value": "thailand",
    "label": "태국"
  },
  {
    "value": "vietnam",
    "label": "배트남"
  }
]
export const nations =
{
  "": "전체",
  "china": "중국",
  "indonesia": "인도네시아",
  "japan": "일본",
  "korea": "대한민국",
  "taiwan": "대만",
  "thailand": "태국",
  "vietnam": "배트남"
}

// 브랜드
export const brandOptions =
[
  {
    "value": "anua",
    "label": "anua"
  },
  {
    "value": "covernat",
    "label": "COVERNAT"
  },
  {
    "value": "fallett",
    "label": "Fallett"
  }
]
export const brands =
{
  "anua": "anua",
  "covernat": "COVERNAT",
  "fallett": "Fallett"
}

// 대륙
export const continentOptions =
[
  {
    "value": "",
    "label": "전체"
  },
  {
    "value": "asia",
    "label": "아시아"
  },
  {
    "value": "europe",
    "label": "유럽"
  },
  {
    "value": "nothamerica",
    "label": "북미"
  },
  {
    "value": "southamerica",
    "label": "남미"
  }
]
export const continents = 
{
  "": "전체",
  "asia": "아시아",
  "europe": "유럽",
  "nothamerica": "북미",
  "southamerica": "남미"
}

// 리스크 유형
export const riskOptions =
[
  {
    "value": "",
    "label": "전체"
  },
  {
    "value": "A",
    "label": "A"
  },
  {
    "value": "B",
    "label": "B"
  },
  {
    "value": "C",
    "label": "C"
  },
  {
    "value": "D",
    "label": "D"
  },
  {
    "value": "E",
    "label": "E"
  }
]
export const risks =
{
  "": "전체",
  "A": "A",
  "B": "B",
  "C": "C",
  "D": "D",
  "E": "E",
}

// 소송 진행 상황
export const lawsuitLevelOptions =
[
  {
    "value": "attorney_filing",
    "label": "변호사입건대기"
  },
  {
    "value": "final_wait",
    "label": "종결문서대기"
  },
  {
    "value": "first_wait",
    "label": "1심판결대기"
  },
  {
    "value": "indict_confirm",
    "label": "소송주체확인"
  },
  {
    "value": "indict_submit",
    "label": "공소장제출"
  },
  {
    "value": "legal_wait",
    "label": "법정개정대기"
  },
  {
    "value": "nego_conclude",
    "label": "협상후종결"
  },
  {
    "value": "notary_ready",
    "label": "공증준비"
  },
  {
    "value": "pre_negotation",
    "label": "소송전협상"
  }
]

export const lawsuitLevels =
{
  "attorney_filing": "변호사입건대기",
  "final_wait": "종결문서대기",
  "first_wait": "1심판결대기",
  "indict_confirm": "소송주체확인",
  "indict_submit": "공소장제출",
  "legal_wait": "법정개정대기",
  "nego_conclude": "협상후종결",
  "notary_ready": "공증준비",
  "pre_negotation": "소송전협상"
}

// 활성 비활성
export const activeOptions =
[
  {
    "value": "1",
    "label": "활성"
  },
  {
    "value": "0",
    "label": "비활성"
  }
]
export const actives = 
{
  "1": "활성",
  "0": "비활성"
}
