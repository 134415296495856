import { useParams } from "react-router";
import { Navigate } from "react-router-dom";
import { DeterminDetailContainer } from "../../containers/DeterminDetailContainer";
import { useAuth } from "../../contexts/auth/auth.context";

export const DeterminDetailPage = () => {
  const { user, setUser, global, setGlobal } = useAuth();
  const { _id } = useParams();  

  return (user && user.access && user.access.length > 200 ?
    <DeterminDetailContainer _id={_id} 
    /> : <Navigate to="/login" replace />  
  )
}
