import axios from "./index";

export const getLawsuitList = async (params) => {
  // params에 넘어온 인수중 access token이 들어 있는 경우 떼어서 header에 넣기
  let data = {}
  data['params'] = params;
  // if (params.access && params.access.length > 0) {
  //   data['headers'] = {Authorization: `Bearer ${params.access}`};
  // }
  // delete data.params.access;

  const response = await axios.get("/lawsuits/", data);
  return response.data;
};

export const getLawsuitDetail = async (params) => {
  // params에 넘어온 인수중 access token이 들어 있는 경우 떼어서 header에 넣기
  let data = {}
  // data['headers'] = {Authorization: `Bearer ${params.access}`};

  const response = await axios.get(`/lawsuits/${params.id}/`, data );
  return response.data;
};

class LawsuitApi {
  // 판별품 데이터 생성
  static InsertLawsuit = (formData) => {
    return axios.post(`/productlawsuits/`, formData, { 
      headers: {
        "Content-Type": "multipart/form-data"
      },
    });
    // return axios.post(`/productlawsuits/`, formData, {headers:{"Content-Type": "application/json; charset=utf-8"}});

  };
}
export default LawsuitApi;