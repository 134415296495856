import { all } from "redux-saga/effects";
import { combineReducers } from "redux";
import counter, { counterSaga } from "./counter";
import posts, { postSaga } from "./posts";
import authReducer, { authSaga } from "./auth";
import forgeryReducer, { forgerySaga } from "./forgerys";
import productReducer, { productSaga } from "./products";
import collectReducer, { collectSaga } from "./collects";
import determinReducer, { determinSaga } from "./determins";
import categoryReducer, { categorySaga } from "./categorys";
import iprReducer, { iprSaga } from "./iprs";
import lawsuitReducer, { lawsuitSaga } from "./lawsuits";
import sellerReducer, { sellerSaga } from "./sellers";
import malsellerReducer, { malsellerSaga } from "./malsellers";
import reportReducer, { reportSaga } from "./reports";
import assessReducer, { assessSaga } from "./assesses";
import investgateReducer, { investgateSaga } from "./investgates";
import monitoringReducer, { monitoringSaga } from "./monitorings";
import dashboardReducer, { dashboardSaga } from "./dashboards";
import inquestReducer, { inquestSaga } from "./inquests";
import merchantReducer, { merchantSaga } from "./merchants";

const rootReducer = combineReducers({
  counter,
  posts,
  authReducer,
  productReducer,
  collectReducer,
  determinReducer,
  forgeryReducer,
  iprReducer,
  lawsuitReducer,
  categoryReducer,
  malsellerReducer,
  sellerReducer,
  reportReducer,
  assessReducer,
  investgateReducer,
  monitoringReducer,
  dashboardReducer,
  inquestReducer,
  merchantReducer,
});

export function* rootSaga() {
  yield all([
    counterSaga(),
    postSaga(),
    authSaga(),
    forgerySaga(),
    productSaga(),
    collectSaga(),
    determinSaga(),
    iprSaga(),
    lawsuitSaga(),
    categorySaga(),
    malsellerSaga(),
    sellerSaga(),
    reportSaga(),
    assessSaga(),
    investgateSaga(),
    monitoringSaga(),
    dashboardSaga(),
    inquestSaga(),
    merchantSaga(),
  ]);
}

export default rootReducer;
