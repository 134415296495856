import MalsellerApi from "../api/malsellers";

import {
  reducerUtils,
  handleAsyncActionsList,
  handleAsyncActionsDetail,
  createPromiseSagaList,
  createPromiseSagaDetail,
} from "../lib/asyncUtils";
import { takeEvery, getContext } from "redux-saga/effects";

/* 액션 타입 */

// 여러개 조회하기
const GET_MALSELLERLIST = "GET_MALSELLERLIST";
const GET_MALSELLERLIST_SUCCESS = "GET_MALSELLERLIST_SUCCESS";
const GET_MALSELLERLIST_ERROR = "GET_MALSELLERLIST_ERROR";

// 상품 항목 조회하기
const GET_MALSELLERDETAIL = "GET_MALSELLERDETAIL";
const GET_MALSELLERDETAIL_SUCCESS = "GET_MALSELLERDETAIL_SUCCESS";
const GET_MALSELLERDETAIL_ERROR = "GET_MALSELLERDETAIL_ERROR";

// 액션 생성 함수
export const getMalsellerList = (nation, platform, risk, searchType, searchText, page) => ({
  type: GET_MALSELLERLIST,
  payload: { nation: nation, platform: platform, risk: risk, searchType: searchType, searchText: searchText, page: page },
});
export const getMalsellerDetail = (seller, page) => ({
  type: GET_MALSELLERDETAIL,
  payload: { seller: seller, page: page },
});

// GET_MALSELLERLIST라는 액션이 발생되면 MalsellerApi.getMalsellerList라는 특정 작업을 수행
const getMalsellerListSaga = createPromiseSagaList(
  GET_MALSELLERLIST,
  MalsellerApi.getMalsellerList
);
const getMalsellerDetailSaga = createPromiseSagaDetail(
  GET_MALSELLERDETAIL,
  MalsellerApi.getMalsellerDetail
);

// 사가들을 합치기(특정액션(GET_MALSELLERLIST)을 모니터링하고 있다가 액션이 발생되면 getMalsellerListSaga 인스턴스 실행)
export function* malsellerSaga() {
  yield takeEvery(GET_MALSELLERLIST, getMalsellerListSaga);
  yield takeEvery(GET_MALSELLERDETAIL, getMalsellerDetailSaga);
}

// initialState 쪽도 반복되는 코드를 initial() 함수를 사용해서 리팩토링 했습니다.
const initialState = {
  malsellerlist: reducerUtils.initial(),
  malsellerdetail: reducerUtils.initial(),
};

export default function malsellerReducer(state = initialState, action) {
  switch (action.type) {
    case GET_MALSELLERLIST:
    case GET_MALSELLERLIST_SUCCESS:
    case GET_MALSELLERLIST_ERROR:
      const reducer = handleAsyncActionsList(
        GET_MALSELLERLIST,
        "malsellerlist",
        true
      );
      return reducer(state, action);

    case GET_MALSELLERDETAIL:
    case GET_MALSELLERDETAIL_SUCCESS:
    case GET_MALSELLERDETAIL_ERROR:
      const reducer2 = handleAsyncActionsList(
        GET_MALSELLERDETAIL,
        "malsellerdetail",
        true
      );
      return reducer2(state, action);
    default:
      return state;
  }
}
