import * as forgeryApi from "../api/forgerys";

import {
  reducerUtils,
  handleAsyncActionsList,
  handleAsyncActionsDetail,
  createPromiseSagaList,
  createPromiseSagaDetail,
} from "../lib/asyncUtils";
import { takeEvery, getContext, takeLatest } from "redux-saga/effects";

/* 액션 타입 */

// 상품목록 조회하기
const GET_FORGERYLIST = "GET_FORGERYLIST";
const GET_FORGERYLIST_SUCCESS = "GET_FORGERYLIST_SUCCESS";
const GET_FORGERYLIST_ERROR = "GET_FORGERYLIST_ERROR";

// 상품 항목 조회하기
const GET_FORGERYDETAIL = "GET_FORGERYDETAIL";
const GET_FORGERYDETAIL_SUCCESS = "GET_FORGERYDETAIL_SUCCESS";
const GET_FORGERYDETAIL_ERROR = "GET_FORGERYDETAIL_ERROR";

// 상품 항목 조회하기
const GET_FORGERYREPORT = "GET_FORGERYREPORT";
const GET_FORGERYREPORT_SUCCESS = "GET_FORGERYREPORT_SUCCESS";
const GET_FORGERYREPORT_ERROR = "GET_FORGERYREPORT_ERROR";

const CLEAR_FORGERYTLIST = "CLEAR_FORGERYTLIST";

export const getForgeryList = (brand, nation, platform, infringe, searchType, searchText, sort, page, access) => ({
  type: GET_FORGERYLIST,
  payload: { brand: brand, nation: nation, platform: platform, infringe: infringe, searchType: searchType, searchText: searchText, sort: sort, page: page, access: access },
});

export const getForgeryDetail = (access, id) => ({
  type: GET_FORGERYDETAIL,
  payload: { access: access, id: id },
  meta: id,
});

export const getForgeryReport = (access, id) => ({
  type: GET_FORGERYREPORT,
  payload: { access: access, id: id },
  meta: id,
});

export const clearForgeryList = () => ({ type: CLEAR_FORGERYLIST });

const getForgeryListSaga = createPromiseSagaList(
  GET_FORGERYLIST,
  forgeryApi.getForgeryList
);

const getForgeryDetailSaga = createPromiseSagaDetail(
  GET_FORGERYDETAIL,
  forgeryApi.getForgeryDetail
);

const getForgeryReportSaga = createPromiseSagaDetail(
  GET_FORGERYREPORT,
  forgeryApi.getForgeryReport
);

// 사가들을 합치기
export function* forgerySaga() {
  yield takeEvery(GET_FORGERYLIST, getForgeryListSaga);
  yield takeEvery(GET_FORGERYDETAIL, getForgeryDetailSaga);
  yield takeEvery(GET_FORGERYREPORT, getForgeryReportSaga);
}

// initialState 쪽도 반복되는 코드를 initial() 함수를 사용해서 리팩토링 했습니다.
const initialState = {
  forgerylist: reducerUtils.initial(),
  forgerydetail: reducerUtils.initial(),
  forgeryreport: reducerUtils.initial(),
};

export default function forgeryReducer(state = initialState, action) {
  switch (action.type) {
    case GET_FORGERYLIST:
    case GET_FORGERYLIST_SUCCESS:
    case GET_FORGERYLIST_ERROR:
      const reducerList = handleAsyncActionsList(
        GET_FORGERYLIST,
        "forgerylist",
        true
      );
      return reducerList(state, action);

    case GET_FORGERYDETAIL:
    case GET_FORGERYDETAIL_SUCCESS:
    case GET_FORGERYDETAIL_ERROR:
      const reducerDetail = handleAsyncActionsDetail(
        GET_FORGERYDETAIL,
        "forgerydetail",
        true
      );
      return reducerDetail(state, action);

    case GET_FORGERYREPORT:
    case GET_FORGERYREPORT_SUCCESS:
    case GET_FORGERYREPORT_ERROR:
      const reducerReport = handleAsyncActionsDetail(
        GET_FORGERYREPORT,
        "forgeryreport",
        true
      );
      return reducerReport(state, action);
    
    case CLEAR_FORGERYTLIST:
      return {
        ...state,
        forgerylist: reducerUtils.initial(),
      };

    default:
      return state;
  }
}
