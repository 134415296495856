import axios from "./index";

export const getDashboardList = async (params) => {
  // params에 넘어온 인수중 access token이 들어 있는 경우 떼어서 header에 넣기
  let data = {}
  data['params'] = params;
  // if (params.access && params.access.length > 0) {
  //   data['headers'] = {Authorization: `Bearer ${params.access}`};
  // }
  // delete data.params.access;

  const response = await axios.get("/monitorings/", data);
  return response.data;
};

export const getDashboardDetail = async (params) => {
  // params에 넘어온 인수중 access token이 들어 있는 경우 떼어서 header에 넣기
  let data = {}
  // data['headers'] = {Authorization: `Bearer ${params.access}`};

  const response = await axios.get(`/dashboards/${params.id}/`, data);
  return response.data;
};

class DashboardApi {
  // 차단실적 토탈 집계
  static reportTotalSummary = (data) => {
    const response = axios.get(`/report_total_summary/`, data);
    return response; 
  };
  
  // 판별품 국가별 집계
  static determinedNationSummary = (data) => {
    const response = axios.get(`/determined_nation_summary/`, data);
    return response; 
  };

  // 판별품 플랫폼별 집계
  static determinedPlatformSummary = (data) => {
    const response = axios.get(`/determined_platform_summary/`, data);
    return response; 
  };

  // 판별품 침해유형별 집계
  static determinedInfringeSummary = (data) => {
    const response = axios.get(`/determined_infringe_summary/`, data);
    return response; 
  };

  // 판별품 모델별 집계
  static determinedModelSummary = (data) => {
    const response = axios.get(`/determined_model_summary/`, data);
    return response; 
  };

  //차단신고 국가별 집계
  static reportNationSummary = (data) => {
    const response = axios.get(`/report_nation_summary/`, data);
    return response; 
  };

  // 차단신고 플랫폼별 집계
  static reportPlatformSummary = (data) => {
    const response = axios.get(`/report_platform_summary/`, data);
    return response; 
  };

  // 요주의판매상 국가별 집계
  static malsellerNationSummary = (data) => {
    const response = axios.get(`/malseller_nation_summary/`, data);
    return response; 
  };

  // 요주의판매상 플랫폼별 집계
  static malsellerPlatformSummary = (data) => {
    const response = axios.get(`/malseller_platform_summary/`, data);
    return response; 
  };

  // 판별품 날짜별 건수 집계(분석결과)  
  static proceedResult = (data) => {
    const response = axios.get(`/proceed_result/`, data);
    return response; 
  };

  // 차단신고 날짜별 집계(분석결과)  
  static blockedResult = (data) => {
    const response = axios.get(`/blocked_result/`, data);
    return response; 
  };

  // 요주의판매자 날짜별 집계(분석결과)  
  static malsellerResult = (data) => {
    const response = axios.get(`/malseller_result/`, data);
    return response; 
  };

  // 판별품 제품별 날짜별 건수 집계(분석결과)  
  static determinedProductResult = (data) => {
    const response = axios.get(`/determined_product_result/`, data);
    return response; 
  };
}
export default DashboardApi;