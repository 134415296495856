import axios from "./index";

class ReportApi {
  static getReportList = async (params) => {
    // params에 넘어온 인수중 access token이 들어 있는 경우 떼어서 header에 넣기
    let data = {}
    data['params'] = params;
    // if (params.access && params.access.length > 0) {
    //   data['headers'] = {Authorization: `Bearer ${params.access}`};
    // }
    // delete data.params.access;

    const response = await axios.get("/reports/", data);
    return response.data;
  };

  static getReportDetail = async (params) => {
    // params에 넘어온 인수중 access token이 들어 있는 경우 떼어서 header에 넣기
    let data = {}
    // data['headers'] = {Authorization: `Bearer ${params.access}`};

    const response = await axios.get(`/reports/${params.id}/`, data);
    return response.data;
  };

  static postReportCreate = (formData) => {
    return axios.post(`reportcreate/`, formData);
  };

  // 판별품 데이터 생성
  static InsertReport = (formData) => {
    return axios.post(`/reports/`, formData, { 
      headers: {
        "Content-Type": "multipart/form-data"
      },
    });
  };

  static UpdateReport = (id, formData) => {
    let data = `/reports/${id}/`
    return axios.post(`/reports/${id}/`, formData, { 
      headers: {
        "Content-Type": "multipart/form-data"
      },
    });
  };

}

export default ReportApi;
