import axios from "./index";

export const getMonitoringList = async (params) => {
  const response = await axios.get("/monitorings/", { params: params });
  return response.data;
};
export const getMonitoringDetail = async (params) => {
  const response = await axios.get(`/monitorings/${params.id}/`);
  return response.data;
};

class MonitoringApi {
  // 판별품 데이터 생성
  static InsertMonitoring = (formData) => {
    return axios.post(`/productmonitorings/`, formData, { 
      headers: {
        "Content-Type": "multipart/form-data"
      },
    });
    // return axios.post(`/productmonitorings/`, formData, {headers:{"Content-Type": "application/json; charset=utf-8"}});

  };
}
export default MonitoringApi;