import axios from "./index";

export const getIprList = async (params) => {
  const response = await axios.get("/iprs/", { params: params });
  return response.data;
};
export const getIprDetail = async (params) => {
  const response = await axios.get(`/iprs/${params.id}/`);
  return response.data;
};

class IprApi {
  // 지재권 데이터 생성
  static InsertIpr = (formData) => {
    return axios.post(`/productiprs/`, formData, { 
      headers: {
        "Content-Type": "multipart/form-data"
      },
    });
  };

  // 지재권 데이터 재거
  static DeleteIpr = (id) => {
    const data = `/iprs/${id}/`;
    const response = axios.delete(data);
    return response;
  };  

}
export default IprApi;