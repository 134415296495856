import { useParams } from "react-router";
import { Navigate } from "react-router-dom";
import { ReportDetailContainer } from "../../containers/ReportDetailContainer";
import { useAuth } from "../../contexts/auth/auth.context";

export const ReportDetailPage = () => {
  const { user, setUser, global, setGlobal } = useAuth();
  const { id } = useParams();  

  return (user && user.access && user.access.length > 200 ?
    <ReportDetailContainer id={parseInt(id)} 
    /> : <Navigate to="/login" replace />
  );
}
