import React, { useEffect } from "react";
import { useParams } from "react-router";
import { Navigate } from "react-router-dom";
import { IprDetailContainer } from "../../containers/IprDetailContainer";
import { ProductListContainer } from "../../containers/ProductListContainer";
import { useAuth } from "../../contexts/auth/auth.context";

export const IprDetailPage = () => {
  const { user, setUser, global, setGlobal } = useAuth();
  const { id } = useParams();  

  return (user && user.access && user.access.length > 200 ?
    <IprDetailContainer id={parseInt(id)} 
    /> : <Navigate to="/login" replace />
  );
}
