import React, { useEffect } from "react";
import { Link, Navigate, useNavigate, useSearchParams, useNavigationType } from "react-router-dom";
import { LawsuitListContainer } from "../../containers/LawsuitListContainer";
import { useAuth } from "../../contexts/auth/auth.context";

export const LawsuitListPage = () => {
  const { user, setUser, global, setGlobal } = useAuth();
  const navType = useNavigationType();  

  // Filter Variables
  let nation = '';
  let platform = '';
  let lawsuitLevel = '';
  let toDate = '';
  let searchType = '';
  let searchText = '';
  let page = '1';
  if (navType === 'PUSH') {
    localStorage.setItem('nation', nation);
    localStorage.setItem('platform', platform);
    localStorage.setItem('lawsuitLevel', lawsuitLevel);
    localStorage.setItem('toDate', toDate);
    localStorage.setItem('searchType', searchType);
    localStorage.setItem('searchText', searchText);
    localStorage.setItem('page', page);
  } else {
    nation = localStorage.getItem('nation');
    platform = localStorage.getItem('platform');
    lawsuitLevel = localStorage.getItem('lawsuitLevel');
    toDate = localStorage.getItem('toDate');
    searchType = localStorage.getItem('searchType');
    searchText = localStorage.getItem('searchText');
    page = localStorage.getItem('page');
  }
  return (user && user.access && user.access.length > 200 ?
    <LawsuitListContainer 
      nationParam={nation} 
      platformParam={platform} 
      lawsuitLevelParam={lawsuitLevel} 
      toDateParam={toDate} 
      searchTypeParam={searchType} 
      searchTextParam={searchText} 
      pageParam={parseInt(page)}       
    /> : <Navigate to="/login" replace />  
    );
}

