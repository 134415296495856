import { Navigate } from "react-router-dom";
import { useAuth } from "../../contexts/auth/auth.context";
import { ChangePasswordContainer } from "../../containers/ChangePasswordContainer";

export const ChangePasswordPage = () => {
  const { user, setUser, global, setGlobal } = useAuth();
  
  return (user && user.access && user.access.length > 200 ?
    <ChangePasswordContainer 
    /> : <Navigate to="/login" replace />  
  );
}

