import { Navigate } from "react-router-dom";
import { useParams } from "react-router";
import { CollectDetailContainer } from "../../containers/CollectDetailContainer";
import { useAuth } from "../../contexts/auth/auth.context";

export const CollectDetailPage = () => {
  const { user, setUser, global, setGlobal } = useAuth();
  const { _id } = useParams();  
  return (user && user.access && user.access.length > 200 ?
    <CollectDetailContainer _id={_id} 
    /> : <Navigate to="/login" replace />  
  )
}
