import axios from "./index";

export const getForgeryList = async (params) => {
  // params에 넘어온 인수중 access token이 들어 있는 경우 떼어서 header에 넣기
  let data = {}
  data['params'] = params;
  // if (params.access && params.access.length > 0) {
  //   data['headers'] = {Authorization: `Bearer ${params.access}`};
  // }
  // delete data.params.access;

  const response = await axios.get("/forgerys/", data);
  return response.data;
};

export const getForgeryDetail = async (params) => {
  // params에 넘어온 인수중 access token이 들어 있는 경우 떼어서 header에 넣기
  let data = {}
  // data['headers'] = {Authorization: `Bearer ${params.access}`};

  const response = await axios.get(`/forgerys/${params.id}/`, data);
  return response.data;
};

export const getForgeryReport = async (params) => {
  // params에 넘어온 인수중 access token이 들어 있는 경우 떼어서 header에 넣기
  let data = {}
  // data['headers'] = {Authorization: `Bearer ${params.access}`};

  const response = await axios.get(`/forgerys/${params.id}/`, data);
  return response.data;
};

class ForgeryApi {
  // 판별품 데이터 생성
  static InsertForgery = (formData) => {
    return axios.post(`/productforgerys/`, formData, { 
      headers: {
        "Content-Type": "multipart/form-data"
      },
    });
  };

  // 위조품 데이터 재거
  static DeleteForgery = (id) => {
    const data = `/forgerys/${id}/`;
    const response = axios.delete(`/forgerys/${id}/`);
    return response;
  };  
  
}
export default ForgeryApi;