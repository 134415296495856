import React, { useState, useRef, useContext, useEffect } from "react";
import { MainLogo } from "../MainLogo";
import { RightMenu } from "../RightMenu";
import { LeftMenu } from "../LeftMenu";
import "./style.css";

import axios from 'axios';
import { useAuth } from "../../contexts/auth/auth.context";
import { BACKEND_SERVER_BASEURL } from "../../config/constant";
import { FaRegStar, FaHeart } from 'react-icons/fa6'
import ConfirmContext from '../../contexts/confirm/ConfirmContext';
import { Link, useNavigate } from "react-router-dom";
import DeterminedApi from "../../api/determineds";
import DeterminApi from "../../api_elastic/determins";
import useForm from '../hooks/useForm';
import { infringeTypes, infringeTypeOptions } from "../../config/codes";

export const DeterminDetail = ({dtm}) => {
  const [ position, setPostion ] = useState({ header: false, mainer:false, footer:false,});
  const [ bodyBottom, setBodyBottom ] = useState(100000);
  
  const { user, setUser, global, setGlobal } = useAuth();
  const [ determinDesc, setDeterminDesc ] = useState('');
  const [ reviewed, setReviewed ] = useState(0);  
  const [ jump, setJump ] = useState('');
  const [ fixedTab, setFixedTab ] = useState(false);
  const { confirm: confirmComp } = useContext(ConfirmContext);

  const [ thumbClickedUrl, setThumbClickedUrl ] = useState(dtm._source.thumb1);
  const [error, setError] = useState("");
  const [{infringe, content}, onChange, onReset] = useForm({
    'infringe': '',
    'content': ''
  });


  const [selectedInfringeOption, setSelectedInfringeOption] = useState(infringeTypeOptions[1].value);
  const handleSelectInfringeChange = (event) => {
    let value = event.target.value;
    setSelectedInfringeOption(value);
  };

  const navigate = useNavigate();

  function image_similarity_string(image_similar_list) {
    let data = '';
    image_similar_list.map((image_similar, index, total) => {
      if (index < 10)
        data += image_similar.similarity.toFixed(2) + ', ';
    });
    if (data.substring(data.length-2) === ", ") {
      data = data.substring(0, data.length-2);
    } 
    return data;
  }


  const onDelete = (_id, e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("mall", dtm._source.mall);
    formData.append("determin_code", dtm._source.determin_code);
    formData.append("determin_name", dtm._source.determin_name);
    formData.append("infringe", "confirmed");

    // 이 수집품은 삭제되었음을 서버에 전송
    DeterminedApi.InsertConfirmed(formData).then(response => {
      if(response.status == 200 || response.status == 201) {
        // 엘라스틱에서 이 수집품 데이터 삭제
        const params = { query : { term : { _id : { value : `${_id}` } } } }
        DeterminApi.DeleteDetermin(params).then(response => {
          if(response.status == 200) {
            alert(`수집품 데이터가 삭제되었습니다.`);
            navigate('/determins');
          } else {
            alert(response.data.msg)
          }
        }).catch(error => {
          console.log(error.message);
          alert(error.message)
        })
      } else {
        alert(response.statusText)
      }
    }).catch(error => {
      console.log(error.message);
      alert(error.message)
    })
  }  

  function isNumeric(data) {
    return !isNaN(data);
  }
    
  // validate Input Columns
  const validateInputs = () => {
    // if (determinDesc.length <= 0) {
    //   const result = alert("침해된 이미지를 선택해 주세요!");
    //   return false;
    // }
    if (selectedInfringeOption.length <= 0) {
      const result = alert("침해 사유를 선택해 주세요!");
      return false;
    }
    else if (content.length <= 0) {
      const result = alert("침해 내용을 기술해 주세요!");
      return false;
    }
    return true;
  }

  function InsertDeterminedData(_id, formData) {
    DeterminedApi.InsertDetermined(formData).then(response => {
      if(response.status == 200 || response.status == 201) {
        // 엘라스틱에서 이 수집품 데이터 삭제
        const params = { query : { term : { _id : { value : `${_id}` } } } }
        DeterminApi.DeleteDetermin(params).then(response => {
          if(response.status == 200) {
          } else {
          }
        }).catch(error => {
        })
        alert("정상적으로 저장되었습니다.")
        navigate(-1);
      } else {
        alert(response.statusText)
      }
    }).catch(error => {
      console.log(error.message);
      alert(error.message)
    })
  }

  function postDeterminedByClicked(_id, data_list) {
    let formData = new FormData();
    formData.append("mall", dtm._source.mall);
    formData.append("category", dtm._source.category);
    formData.append("brand", dtm._source.brand);
    formData.append("model", dtm._source.model);
    formData.append("product_name", dtm._source.product_name);
    formData.append("determin_code", dtm._source.determin_code);
    formData.append("determin_name", dtm._source.determin_name);
    formData.append("product_price", dtm._source.product_price);
    formData.append("price_floor", dtm._source.price_floor);
    formData.append("determin_price", dtm._source.determin_price);
    formData.append("url", dtm._source.url.substring(0,250));
    formData.append("similarity", dtm._source.title_similarity ? dtm._source.title_similarity.toFixed(2) : 0.0);
    formData.append("infringe", selectedInfringeOption);
    formData.append("content", content);
    formData.append("product", isNumeric(dtm._source.product_id) ? parseInt(dtm._source.product_id) : 0);
    formData.append("seller", dtm._source.seller);
    formData.append("seller_home", dtm._source.seller_home);
    formData.append("sold_volume", dtm._source.sold_out ? dtm._source.sold_out : 0);

    formData.append("determins", JSON.stringify(data_list));
    InsertDeterminedData(_id, formData);

    // let downloadedCount = 0;
    // data_list.map( async (data, index, total) => {
    //   try { //, headers: {"Access-Control-Allow-Origin": "*",}
    //     const response = await fetch(data.determin_image);
    //     if (!response.ok) {
    //       throw new Error(`HTTP error! status: ${response.status}`);
    //     }
    //     const blob = response.blob();

    //     downloadedCount += 1;
    //     let now = (new Date()).getTime()+index;
    //     let determin_file = 'determin_' + now + '.' + data.determin_image.substring(data.determin_image.lastIndexOf('.') + 1);
    //     formData.append(`determin_image${index+1}`, new File([blob], determin_file));
    //     data.determin_image = determin_file;
    //     formData.append("determins", JSON.stringify(data_list));
    //     if (downloadedCount === data_list.length) {
    //       InsertDeterminedData(formData);
    //     }
    //   } catch (error) {
    //     console.error('이미지 다운로드 실패:', error);
    //   }
    // });
  }

  const onSubmit = (_id, e) => {
    // e.preventDefault();
    if (validateInputs() == true) {
      let data_list = [];
      dtm._source.product_similar.map((sim, index, total) => {
        if (sim.checked === true) {
          let data_dict = { 
            product_image: sim.product.substring(sim.product.lastIndexOf('media/')+6),
            determin_image: sim.determin, 
            src_name: sim.src_name,
            src_desc: sim.src_desc,
            similarity: sim.similarity,
          };
          if (data_list.length < 5)
            data_list.push(data_dict);
        }
      })
      dtm._source.official_similar.map((sim, index, total) => {
        if (sim.checked === true) {
          let data_dict = { 
            product_image: sim.product.substring(sim.product.lastIndexOf('media/')+6),
            determin_image: sim.determin, 
            src_name: sim.src_name,
            src_desc: sim.src_desc,
            similarity: sim.similarity,
          };
          if (data_list.length < 5)
            data_list.push(data_dict);
        }
      })
      dtm._source.determined_similar.map((sim, index, total) => {
        if (sim.checked === true) {
          let data_dict = { 
            product_image: sim.product.substring(sim.product.lastIndexOf('media/')+6),
            determin_image: sim.determin, 
            src_name: sim.src_name,
            src_desc: sim.src_desc,
            similarity: sim.similarity,
          };
          if (data_list.length < 5)
            data_list.push(data_dict);
        }
      })
      // if (data_list.length > 0) {
      postDeterminedByClicked(_id, data_list);
      // }
    }  
  }
  
  const onImageCheck = (param_sim, e) => {
    e.preventDefault();
    let desc = '';
    dtm._source.product_similar.map((sim, index, total) => {
      if (sim === param_sim) {
        sim.checked = (sim.checked===undefined) ? true : !sim.checked;
      }
      if (sim.checked) {
        let dsc = sim.src_name;
        dsc += (sim.src_desc&&sim.src_desc.length > 0) ? '/' + sim.src_desc : '/';
        dsc += '/' + sim.similarity.toFixed(2);
        desc += desc.length > 0 ? ', ' + dsc : dsc;
      }
    })
    dtm._source.official_similar.map((sim, index, total) => {
      if (sim === param_sim) {
        sim.checked = (sim.checked===undefined) ? true : !sim.checked;
      }
      if (sim.checked) {
        let dsc = sim.src_name;
        dsc += (sim.src_desc&&sim.src_desc.length > 0) ? '/' + sim.src_desc : '/';
        dsc += '/' + sim.similarity.toFixed(2);
        desc += desc.length > 0 ? ', ' + dsc : dsc;
      }
    })
    dtm._source.determined_similar.map((sim, index, total) => {
      if (sim === param_sim) {
        sim.checked = (sim.checked===undefined) ? true : !sim.checked;
      }
      if (sim.checked) {
        let dsc = sim.src_name;
        dsc += (sim.src_desc&&sim.src_desc.length > 0) ? '/' + sim.src_desc : '/';
        dsc += '/' + sim.similarity.toFixed(2);
        desc += desc.length > 0 ? ', ' + dsc : dsc;
      }
    })
    // 화면상에 침해 내용 출력
    setDeterminDesc(desc);
  }

  const onContentChange = e => {
    const { value, name } = e.target; // 우선 e.target 에서 name 과 value 를 추출
    setFormData({
      ...formData,  // 기존의 input 객체를 복사한 뒤
      [name]: value // name 키를 가진 값을 value 로 설정
    });
    console.log(value);
  };

  const onThumbClick = (thumburl, e) => {
    e.preventDefault();
    setThumbClickedUrl(thumburl)
  }

  function displayImage(imgUrl) {
    if (imgUrl.startsWith('data/')) {
      imgUrl = `${BACKEND_SERVER_BASEURL}/media/${imgUrl}`;
    } else if (imgUrl.startsWith('/media/https%3A/')) {
      imgUrl = imgUrl.replace('/media/https%3A/', 'https://')
    } else if (imgUrl.startsWith('/media/')) {
      imgUrl = `${BACKEND_SERVER_BASEURL}${imgUrl}`;
    }
    // console.log('imgUrl : ' + imgUrl);
    return imgUrl;
  }
  
  function displayLocalDate(utc_dt) {
    let local_dt = moment(utc_dt, 'YYYY-MM-DDTHH:mm:ss');
    local_dt = local_dt.add(9, 'hours');
    return local_dt.format('YYYY-MM-DD');
  }

  return (
    <div className="determin-detail">
      <div className="div-8">
        <div className="main-frame-2">
          <div className="bulletin-body-title-2">
            <div className="title-4">위조품 판별 상세</div>
          </div>

          <div className="body-sub-title-3" />

          <div className="section-frame-6">
            <div className="frame-21">
              <div className="img-wrapper">
                {/* <img className="img" alt="Div image" src="https://cdn.animaapp.com/projects/6614ef6f5f16da1152470b09/releases/678a14b5645cc1fc8fa198e4/img/div-image-2-2.png" /> */}
                <div className="img" style={{backgroundImage: `url(${thumbClickedUrl})`, backgroundSize:'contain', backgroundRepeat:'no-repeat', backgroundPosition:'center'}} />
              </div>

              <div className="frame-22">
                {/* <div className="frame-23" />
                <div className="frame-24" />
                <div className="frame-25" />
                <div className="frame-26" />
                <div className="frame-27" /> */}
                { 
                [dtm._source.thumb1, dtm._source.thumb2, dtm._source.thumb3, dtm._source.thumb4, dtm._source.thumb5].map((thumburl, index, total) => (
                  thumburl && thumburl.length > 0 ?
                  <div className={`frame-${index+23}`} style={{cursor:'pointer', border:'1px solid', backgroundImage: `url(${thumburl})`, backgroundSize:'contain'}} onClick={(e)=>{onThumbClick(thumburl, e)}} key={index} />
                  : null
                ))
              }

              </div>
            </div>

            <div className="frame-28">
              <div className="frame-29">
                <div className="text-wrapper-22">브랜드 : {dtm._source.brand}</div>
              </div>

              <div className="frame-29">
                <div className="text-wrapper-22">쇼핑몰 : {dtm._source.mall} / {dtm._source.category}</div>
              </div>

              <div className="frame-30">
                <p className="text-wrapper-23">
                {dtm._source.determin_name.substring(0,100)}
                </p>
              </div>

              <div className="frame-29">
                <div className="text-wrapper-22">판매가 : {dtm._source.determin_price.toLocaleString('ko-KR') }({dtm._source.currency})</div>
              </div>

              <div className="frame-29">
                <div className="text-wrapper-22">정품하한 : {dtm._source.price_floor.toLocaleString('ko-KR')}</div>
              </div>

              <div className="frame-29">
                <a className="text-wrapper-22" href={dtm._source.seller_home} target="_blank" style={{"color": "blue"}}>판매자 : {dtm._source.seller} Home</a>
              </div>

              <div className="frame-29">
                <a className="text-wrapper-22" href={dtm._source.url} target="_blank" style={{"color": "blue"}}>쇼핑몰 수집품 상세보기</a>
              </div>

              <div className="frame-29">
                <div className="text-wrapper-22">수집형태 : {dtm._source.search_type}</div>
              </div>

              <div className="frame-29">
                <div className="text-wrapper-22">이미지유사도 : {image_similarity_string(dtm._source.product_similar)}</div>
              </div>

              <div className="frame-29">
                <div className="text-wrapper-22">공홈유사도 : {image_similarity_string(dtm._source.official_similar)}</div>
              </div>

              <div className="frame-29">
                <div className="text-wrapper-22">판별품유사도 : {image_similarity_string(dtm._source.determined_similar)}</div>
              </div>

            </div>
          </div>

          <div className="section-frame-7">
            <div className="frame-31">
              <div className="text-wrapper-24">타이틀유사도</div>
            </div>

            <div className="frame-32">
              <div className="frame-33">
                <div className="text-wrapper-25">정품 제목</div>
              </div>

              <div className="frame-34">
                <div className="text-wrapper-25">{dtm._source.product_name}</div>
              </div>

              <div className="frame-34">
                <div className="text-wrapper-25">위조품 제목</div>
              </div>

              <div className="frame-34">
                <div className="text-wrapper-25">{dtm._source.determin_name.substring(0,50)}</div>
              </div>
            </div>

            <div className="frame-32">
              <div className="frame-35">
                <div className="text-wrapper-25">브랜드/모델</div>
              </div>

              <div className="frame-36">
                <div className="text-wrapper-25">{dtm._source.brand} / {dtm._source.model}</div>
              </div>

              <div className="frame-36">
                <div className="text-wrapper-25">타이틀 유사도</div>
              </div>

              <div className="frame-36">
                <div className="text-wrapper-25">{dtm._source.title_similarity ? dtm._source.title_similarity.toFixed(2) : null}</div>
              </div>
            </div>
          </div>

          <div className="section-frame-7">
            <div className="frame-37">
              <div className="text-wrapper-24">가격 유사도({dtm._source.currency})</div>
            </div>

            <div className="frame-32">
              <div className="frame-33">
                <div className="text-wrapper-25">정품 기준가</div>
              </div>

              <div className="frame-34">
                <div className="text-wrapper-25">{dtm._source.product_price ? dtm._source.product_price.toLocaleString('ko-KR') : null}</div>
              </div>

              <div className="frame-34">
                <div className="text-wrapper-25">위조품 판매가</div>
              </div>

              <div className="frame-34">
                <div className="text-wrapper-25">{dtm._source.determin_price ? dtm._source.determin_price.toLocaleString('ko-KR') : null}</div>
              </div>
            </div>

            <div className="frame-32">
              <div className="frame-35">
                <div className="text-wrapper-25">정품 하한가</div>
              </div>

              <div className="frame-36">
                <div className="text-wrapper-25">{dtm._source.price_floor ? dtm._source.price_floor.toLocaleString('ko-KR') : null}</div>
              </div>

              <div className="frame-36">
                <div className="text-wrapper-25">정품 하한가 기준</div>
              </div>

              <div className="frame-36">
                <div className="text-wrapper-25">{(dtm._source.determin_price - dtm._source.price_floor).toLocaleString('ko-KR')}</div>
              </div>
            </div>
          </div>

          {/* // 정품 이미지 유사도 */}
          <div className="section-frame-8">
            <div className="frame-29">
              <div className="text-wrapper-24">정품 이미지 유사도</div>
            </div>
          </div>

          <div className="section-frame-9">

          { dtm._source.product_similar && dtm._source.product_similar.map((sim, index, total) => (
            <div className="rec-item-frame-2">
              <div className="div-9">
                <div className="thumbnail-3">
                  <div className="overlap-group-3">
                    <div className="back-image-wrapper">
                      {/* <img className="back-image-2" alt="Back image" src="https://cdn.animaapp.com/projects/6614ef6f5f16da1152470b09/releases/678a14b5645cc1fc8fa198e4/img/backimage.png" /> */}
                      <div className="back-image-2" style={{backgroundImage: `url(${sim.product})`, backgroundSize:'contain', backgroundRepeat:'no-repeat', backgroundPosition:'center'}} />

                    </div>

                    {/* <button className="button-4">
                      <div className="button-box-3"></div>
                    </button> */}
                    <button className="button-4" style={{cursor:'pointer'}} onClick={(e)=>{onImageCheck(sim, e)}}>
                      <div className="button-box-3"><FaHeart size="24px" fill={sim.checked > 0 ? "tomato" : "gray"}/></div>
                    </button>

                  </div>
                </div>

                <div className="info-frame-2">
                  <div className="frame-38">
                    <div className="frame-35">
                      <div className="text-wrapper-25">{sim.src_name}</div>
                    </div>

                    <div className="frame-36">
                      <div className="text-wrapper-25">{sim.src_desc}</div>
                    </div>
                  </div>

                  <div className="frame-38">
                    <div className="frame-35">
                      <div className="text-wrapper-25">&nbsp;</div>
                    </div>

                    <div className="frame-36">
                      <div className="text-wrapper-25">&nbsp;</div>
                    </div>
                  </div>

                  <div className="frame-39" />
                </div>
              </div>

              <div className="div-9">
                <div className="thumbnail-4">
                  <div className="overlap-group-3">
                    <div className="back-image-wrapper">
                      {/* <img className="back-image-2" alt="Back image" src="https://cdn.animaapp.com/projects/6614ef6f5f16da1152470b09/releases/678a14b5645cc1fc8fa198e4/img/backimage-1.png" /> */}
                      {/* <img className="back-image-2" style={{backgroundImage: `url(${sim.determin})`, backgroundSize:'contain'}}/> */}
                      <div className="back-image-2" style={{backgroundImage: `url(${sim.determin})`, backgroundSize:'contain', backgroundRepeat:'no-repeat', backgroundPosition:'center'}} />

                    </div>

                    {/* <button className="button-4">
                      <div className="button-box-3"></div>
                    </button> */}
                    <button className="button-4" style={{cursor:'pointer'}} onClick={(e)=>{onImageCheck(sim, e)}}>
                      <div className="button-box-3"><FaHeart size="24px" fill={sim.checked > 0 ? "tomato" : "gray"}/></div>
                    </button>

                  </div>
                </div>

                <div className="info-frame-2">
                  <div className="frame-38">
                    <div className="frame-36">
                      <div className="text-wrapper-25">similarity</div>
                    </div>

                    <div className="frame-36">
                      <div className="text-wrapper-25">{sim.similarity ? sim.similarity.toFixed(2) : null}</div>
                    </div>
                  </div>

                  <div className="frame-38">
                    <div className="frame-36">
                      <div className="text-wrapper-25">&nbsp;</div>
                    </div>

                    <div className="frame-36">
                      <div className="text-wrapper-25">&nbsp;</div>
                    </div>
                  </div>

                  <div className="frame-39" />
                </div>
              </div>
            </div>
          ))}
          </div>

          {/* // 공홈 이미지 유사도 */}
          <div className="section-frame-8">
            <div className="frame-29">
              <div className="text-wrapper-24">공홈 이미지 유사도</div>
            </div>
          </div>

          <div className="section-frame-9">

          { dtm._source.official_similar && dtm._source.official_similar.map((sim, index, total) => (
            <div className="rec-item-frame-2">
              <div className="div-9">
                <div className="thumbnail-3">
                  <div className="overlap-group-3">
                    <div className="back-image-wrapper">
                      {/* <img className="back-image-2" alt="Back image" src="https://cdn.animaapp.com/projects/6614ef6f5f16da1152470b09/releases/678a14b5645cc1fc8fa198e4/img/backimage.png" /> */}
                      {/* <div className="back-image-2" style={{backgroundImage: `url(${sim.product})`, backgroundSize:'contain', backgroundRepeat:'no-repeat'}} /> */}
                      <div className="back-image-2" style={{backgroundImage: `url(${sim.product})`, backgroundSize:'contain', backgroundRepeat:'no-repeat', backgroundPosition:'center'}} />

                    </div>

                    {/* <button className="button-4">
                      <div className="button-box-3"></div>
                    </button> */}
                    <button className="button-4" style={{cursor:'pointer'}} onClick={(e)=>{onImageCheck(sim, e)}}>
                      <div className="button-box-3"><FaHeart size="24px" fill={sim.checked > 0 ? "tomato" : "gray"}/></div>
                    </button>

                  </div>
                </div>

                <div className="info-frame-2">
                  <div className="frame-38">
                    <div className="frame-35">
                      <div className="text-wrapper-25">{sim.src_name}</div>
                    </div>

                    <div className="frame-36">
                      <div className="text-wrapper-25">{sim.src_desc}</div>
                    </div>
                  </div>

                  <div className="frame-38">
                    <div className="frame-35">
                      <div className="text-wrapper-25">&nbsp;</div>
                    </div>

                    <div className="frame-36">
                      <div className="text-wrapper-25">&nbsp;</div>
                    </div>
                  </div>

                  <div className="frame-39" />
                </div>
              </div>

              <div className="div-9">
                <div className="thumbnail-4">
                  <div className="overlap-group-3">
                    <div className="back-image-wrapper">
                      {/* <img className="back-image-2" alt="Back image" src="https://cdn.animaapp.com/projects/6614ef6f5f16da1152470b09/releases/678a14b5645cc1fc8fa198e4/img/backimage-1.png" /> */}
                      {/* <img className="back-image-2" style={{backgroundImage: `url(${sim.determin})`, backgroundSize:'contain'}}/> */}
                      <div className="back-image-2" style={{backgroundImage: `url(${sim.determin})`, backgroundSize:'contain', backgroundRepeat:'no-repeat', backgroundPosition:'center'}} />

                    </div>

                    {/* <button className="button-4">
                      <div className="button-box-3"></div>
                    </button> */}
                    <button className="button-4" style={{cursor:'pointer'}} onClick={(e)=>{onImageCheck(sim, e)}}>
                      <div className="button-box-3"><FaHeart size="24px" fill={sim.checked > 0 ? "tomato" : "gray"}/></div>
                    </button>

                  </div>
                </div>

                <div className="info-frame-2">
                  <div className="frame-38">
                    <div className="frame-36">
                      <div className="text-wrapper-25">similarity</div>
                    </div>

                    <div className="frame-36">
                      <div className="text-wrapper-25">{sim.similarity ? sim.similarity.toFixed(2) : null}</div>
                    </div>
                  </div>

                  <div className="frame-38">
                    <div className="frame-36">
                      <div className="text-wrapper-25">&nbsp;</div>
                    </div>

                    <div className="frame-36">
                      <div className="text-wrapper-25">&nbsp;</div>
                    </div>
                  </div>

                  <div className="frame-39" />
                </div>
              </div>
            </div>
          ))}
          </div>

          {/* // 기판별품 이미지 유사도 */}
          <div className="section-frame-8">
            <div className="frame-29">
              <div className="text-wrapper-24">기판별품 이미지 유사도</div>
            </div>
          </div>

          <div className="section-frame-9">

          { dtm._source.determined_similar && dtm._source.determined_similar.map((sim, index, total) => (
            <div className="rec-item-frame-2">
              <div className="div-9">
                <div className="thumbnail-3">
                  <div className="overlap-group-3">
                    <div className="back-image-wrapper">
                      {/* <img className="back-image-2" alt="Back image" src="https://cdn.animaapp.com/projects/6614ef6f5f16da1152470b09/releases/678a14b5645cc1fc8fa198e4/img/backimage.png" /> */}
                      <div className="back-image-2" style={{backgroundImage: `url(${sim.product})`, backgroundSize:'contain', backgroundRepeat:'no-repeat', backgroundPosition:'center'}} />

                    </div>

                    {/* <button className="button-4">
                      <div className="button-box-3"></div>
                    </button> */}
                    <button className="button-4" style={{cursor:'pointer'}} onClick={(e)=>{onImageCheck(sim, e)}}>
                      <div className="button-box-3"><FaHeart size="24px" fill={sim.checked > 0 ? "tomato" : "gray"}/></div>
                    </button>

                  </div>
                </div>

                <div className="info-frame-2">
                  <div className="frame-38">
                    <div className="frame-35">
                      <div className="text-wrapper-25">{sim.src_name}</div>
                    </div>

                    <div className="frame-36">
                      <div className="text-wrapper-25">{sim.src_desc}</div>
                    </div>
                  </div>

                  <div className="frame-38">
                    <div className="frame-35">
                      <div className="text-wrapper-25">&nbsp;</div>
                    </div>

                    <div className="frame-36">
                      <div className="text-wrapper-25">&nbsp;</div>
                    </div>
                  </div>

                  <div className="frame-39" />
                </div>
              </div>

              <div className="div-9">
                <div className="thumbnail-4">
                  <div className="overlap-group-3">
                    <div className="back-image-wrapper">
                      {/* <img className="back-image-2" alt="Back image" src="https://cdn.animaapp.com/projects/6614ef6f5f16da1152470b09/releases/678a14b5645cc1fc8fa198e4/img/backimage-1.png" /> */}
                      {/* <img className="back-image-2" style={{backgroundImage: `url(${sim.determin})`, backgroundSize:'contain'}}/> */}
                      <div className="back-image-2" style={{backgroundImage: `url(${sim.determin})`, backgroundSize:'contain', backgroundRepeat:'no-repeat', backgroundPosition:'center'}} />

                    </div>

                    {/* <button className="button-4">
                      <div className="button-box-3"></div>
                    </button> */}
                    <button className="button-4" style={{cursor:'pointer'}} onClick={(e)=>{onImageCheck(sim, e)}}>
                      <div className="button-box-3"><FaHeart size="24px" fill={sim.checked > 0 ? "tomato" : "gray"}/></div>
                    </button>

                  </div>
                </div>

                <div className="info-frame-2">
                  <div className="frame-38">
                    <div className="frame-36">
                      <div className="text-wrapper-25">similarity</div>
                    </div>

                    <div className="frame-36">
                      <div className="text-wrapper-25">{sim.similarity ? sim.similarity.toFixed(2) : null}</div>
                    </div>
                  </div>

                  <div className="frame-38">
                    <div className="frame-36">
                      <div className="text-wrapper-25">&nbsp;</div>
                    </div>

                    <div className="frame-36">
                      <div className="text-wrapper-25">&nbsp;</div>
                    </div>
                  </div>

                  <div className="frame-39" />
                </div>
              </div>
            </div>
          ))}
          </div>



          <form className="section-frame-10" name="frm" onSubmit={onSubmit} encType="multipart/form-data">
            <div className="form-title-wrapper">
              <div className="form-title-2">판별하기</div>
            </div>

            <div className="form-caption-frame-2">
              <div className="frame-40">
                <div className="form-caption-2">판별 : {determinDesc}</div>
              </div>

              <div className="frame-41">
                <div className="form-determ-tag-2">침해 사유</div>
              </div>

              <div className="frame-42">
                {/* <div className="form-determ-drop-2">판별 사유</div> */}
                <select className="form-determ-drop-2" id="infringe" name="infringe" value={selectedInfringeOption} onChange={handleSelectInfringeChange} style={{border:"none"}}>
                  { infringeTypeOptions.filter((option, index, total) => index > 0).map((option, index, total) => ( 
                    <option value={option.value}>{option.label}</option>
                  ))}
                </select>

              </div>
            </div>

            <div className="form-text-frame-2">
              <div className="frame-43">
                {/* <div className="form-text-2">내용을 입력해 주세요</div> */}
                <textarea className="form-text-2" name="content" value={content} onChange={onChange} style={{border:"none"}}></textarea>

              </div>
            </div>

            <div className="form-submit-frame-2">
              <div className="form-submit-layout-2" style={{cursor:'pointer'}} onClick={(e) => {onDelete(dtm._id, e)}}>
                <div className="form-submit-3">삭&nbsp;&nbsp;제</div>
              </div>

              <div className="form-submit-layout-3" style={{cursor:'pointer'}} onClick={(e) => {onSubmit(dtm._id, e)}}>
                <div className="form-submit-4">저&nbsp;&nbsp;장</div>
              </div>
            </div>
          </form>

        </div>

        <MainLogo className="main-logo-3" />
        <RightMenu className="right-menu-4" />
        <LeftMenu className="left-menu-4" />
      </div>
    </div>
  );
};
