import React, { useState, useRef, useEffect } from "react";
import { MainLogo } from "../../components/MainLogo";
import { RightMenu } from "../../components/RightMenu";
import { LeftMenu } from "../../components/LeftMenu";
import { BACKEND_SERVER_BASEURL } from "../../config/constant";

import "./style.css";
import { Link, useNavigate } from 'react-router-dom';
import AuthApi from "../../api/auth";
import { useAuth } from "../../contexts/auth/auth.context";
import { Input } from "../../components/Input";

export const ChangePassword = () => {
  const { user, setUser, global, setGlobal } = useAuth();
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const [currentPassword, setCurrentPassword] = useState("");
  const currentPasswordRef = useRef();

  const [newPassword, setNewPassword] = useState("");
  const newPasswordRef = useRef();

  const [confirmPassword, setConfirmPassword] = useState("");
  const confirmPasswordRef = useRef();

  const onChange = e => {
    const { value, name } = e.target;
    if (name === 'currentPassword') {
      setCurrentPassword(value);
    }
    else if (name === 'newPassword') {
      setNewPassword(value);
    }
    else if (name === 'confirmPassword') {
      setConfirmPassword(value);
    }
  };

  const validateString = (strData) => {
    //const pattern = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[~@#$!%*?&])[a-zA-Z\d~@#$!%*?&]+$/       
    //영문 + 숫자 + 특수문자 정규식
  	const pattern = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[~@#$!%*?&])[a-zA-Z\d~@#$!%*?&]{8,}$/          
    //영문 + 숫자 + 특수문자 + 8자리 이상 정규식
	  return !/[ㄱ-ㅎㅏ-ㅣ가-힣]/.test(strData) && pattern.test(strData);                           
    //한글 입력 시 false 리턴
  }

  // validate Input Columns
  const validateInputs = () => {
    if (currentPassword.length < 4) {
      const result = alert("'현재 비밀번호'를 입력해 주세요!");
      currentPasswordRef.current.focus();
      return false;
    }
    else if (newPassword.length < 8) {
      const result = alert("'새로운 비밀번호(영문,숫자,특수문자 포함 8바이트 이상)'를 입력해 주세요!");
      newPasswordRef.current.focus();
      return false;
    }
    else if (validateString(newPassword) === false) {
      const result = alert("비밀번호(영문,숫자,특수문자 포함 8바이트 이상) 입력 오류입니다!");
      newPasswordRef.current.focus();
      return false;
    }
    else if (confirmPassword.length < 8) {
      const result = alert("'새로운 비밀번호 확인'을 입력해 주세요!");
      confirmPasswordRef.current.focus();
      return false;
    }
    else if (confirmPassword !== newPassword) {
      const result = alert("'새로운 비밀번호'와 다릅니다. 다시 입력해 주세요!");
      confirmPasswordRef.current.focus();
      return false;
    }
    return true;
  }

  const handleChangePasswordSubmit = e => {
    // e.preventDefault();
    if (validateInputs() === false) {
      return;
    } 
    let formData = new FormData();
    formData.append("currentPassword", currentPassword);
    formData.append("newPassword", newPassword);
    formData.append("confirmPassword", confirmPassword);
    AuthApi.ChangePassword(formData).then(response => {
      if(response.status == 200) {
        alert(`${user.name}님 비밀번호가 변경되었습니다.`)
        navigate('/');
      } else {
        alert(response.data.msg)
      }
    }).catch(error => {
      console.log(error.message);
      alert('비밀번호 변경 오류입니다.')
    })
  }

  return (
    <div className="change-password">
      <div className="div-2">
        <div className="middle-section">
          <LeftMenu className="left-menu-instance" />
          <div className="body-container">
            <div className="login-area">
              <div className="login-text">
                <div className="login-title">
                  <div className="text-wrapper-6">비밀 번호 변경</div>
                </div>

                <div className="login-sub-title">
                  <p className="p">Trust Data 에 가입하신 {user.email} 님에 대한 비밀번호를 변경합니다.</p>
                </div>
              </div>

              <div className="form-login">
                <div className="div-wrapper-2">
                  {/* <div className="text-wrapper-7">현재 비밀번호</div> */}
                  <input className="text-wrapper-7" type='password' name='currentPassword' value={currentPassword} onChange={onChange} ref={currentPasswordRef} placeholder="현재비밀번호" style={{border:"none"}}/>

                </div>

                <div className="div-wrapper-2">
                  {/* <div className="text-wrapper-7">새로운 비밀번호</div> */}
                  <input className="text-wrapper-7" type='password' name='newPassword' value={newPassword} onChange={onChange} ref={newPasswordRef} placeholder="새로운 비밀번호(영문,숫자,특수문자 포함 8바이트 이상)"  style={{border:"none"}}/>
                </div>

                <div className="div-wrapper-2">
                  {/* <div className="text-wrapper-7">새로운 비밀번호 확인</div> */}
                  <input className="text-wrapper-7" type='password' name='confirmPassword' value={confirmPassword} onChange={onChange} ref={confirmPasswordRef} placeholder="새로운 비밀번호 확인"  style={{border:"none"}}/>
                </div>

                <div className="change-password-btn-wrapper" style={{cursor:"pointer", backgroundColor:"#163E64"}} onClick={(e) => handleChangePasswordSubmit(e)}>
                  <div className="change-password-btn">비밀 번호 변경</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="menu-section">
          <MainLogo className="main-logo-instance" />
          <RightMenu className="right-menu-instance" />
        </div>
      </div>
    </div>
  );
};
