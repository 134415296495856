import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ChangePassword } from "../components/ChangePassword";


export const ChangePasswordContainer = () => {
  return (
    <ChangePassword />
  );
}

